import clsx from 'clsx'
import React from 'react'

import CustomText from '../base/CustomText'

type Props = {
  percentage: number
  className?: string
  showPercentage?: boolean
  round: boolean
}

const PercentageBar = ({ showPercentage = true, percentage, className, round = true }: Props): JSX.Element => {
  return (
    <div className={clsx(className, 'w-full  h-2  flex flex-row items-center gap-2')}>
      <div className="flex-1 h-full bg-neutrals-soft-grey-2 rounded-lg overflow-hidden">
        <div className="h-full bg-secondary rounded-lg" style={{ width: `${percentage}%` }} />
      </div>

      {showPercentage ? (
        <CustomText className="text-neutrals-gray-2">{`${round ? Math.round(percentage) : percentage}%`}</CustomText>
      ) : null}
    </div>
  )
}

export default PercentageBar
