import { motion } from 'framer-motion'
import { useState } from 'react'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'

import MuxVideo from '../../MuxVideo'

type Props = {
  /**
   * youtube ID
   */
  addon: object

  /**
   * Classnames
   */
  editable: boolean
  isPostModal: boolean
}

const UploadedVideo = ({ addon, editable = false, isPostModal = false }: Props): JSX.Element => {
  const [openMuxVideo, setOpenMuxVideo] = useState(!editable)

  return (
    <>
      {!openMuxVideo ? (
        <div className="flex flex-row flex-wrap items-center justify-center gap-4 p-2">
          <p className="text-gray text-bold">Se ha cargado de forma correcta</p>
          <Button size={ButtonSize.Medium} appearance={ButtonAppearance.Primary} onClick={() => setOpenMuxVideo(true)}>
            Previsualizar
          </Button>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: '100%' }}
          exit={{ opacity: 0, height: 0 }}
        >
          <MuxVideo customID={`post-playback-${addon.PlaybackID}`} id={addon.PlaybackID} isPostModal={isPostModal} />
        </motion.div>
      )}
    </>
  )
}

export default UploadedVideo
