import Spinner from '@/components/base/Spinner'

const MetaDataLoading = ({ loading, progress }: { loading: boolean; progress: number }): JSX.Element | null => {
  return loading ? (
    <div className="flex items-center justify-center h-20 rounded-b-lg sm:rounded-lg bg-white p-4 text-gray-800 border border-gray-300">
      {progress ? <p className="font-bold text-gray-900">Se ha subido un {Math.floor(progress)}% del video</p> : null}
      <Spinner size={20} className="text-gray-700" />
    </div>
  ) : null
}

export default MetaDataLoading
