import Image from 'next/image'
import { FaCheckCircle } from 'react-icons/fa'

import CustomText, { FontWeight } from '@/components/base/CustomText'
import TokenFront from '@/public/static/images/token-front.png'

export const GroupExchangeApproved = ({ addon }) => {
  const exchangeName = addon.RewardName
  const amount = addon?.TokensAmount ?? 0
  return (
    <div className="bg-white border border-gray-200 rounded-2xl p-2 my-4 flex items-center gap-3">
      <div className="w-[50px] h-[50px] sm:w-[60px] sm:h-[60px]">
        <Image
          className="rounded-xl h-full object-cover w-full"
          src={addon?.Image}
          width={100}
          height={100}
          alt="Exchange"
        />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2">
          <FaCheckCircle className="text-primary" />
          <CustomText weight={FontWeight.SemiBold}>{exchangeName}</CustomText>
        </div>
        <div className="flex items-center gap-2">
          <Image width={18} height={18} src={TokenFront} alt="SNT" />
          <CustomText weight={FontWeight.SemiBold}>
            {amount} <span className="font-normal text-sm">SNT</span>
          </CustomText>
        </div>
      </div>
    </div>
  )
}
