import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'
import * as Icon from 'react-icons/fi'

import { useFloatingCard } from './Context'

const Button = ({
  children,
  asComponent,
  asLink,
  help,
  icon,
  onClick,
  renderCustomIcon,
  linkProps,
  spacingClassName,
  disabled = false,
  ...rest
}): JSX.Element => {
  const { handleClick } = useFloatingCard()
  const Element = asLink ? 'a' : asComponent || 'button'
  const hasIcon = renderCustomIcon || icon

  const getIcon = () => {
    try {
      const Element = Icon['Fi' + icon]
      return Element ? <Element /> : null
    } catch {
      return null
    }
  }

  const content = (
    <Element
      className={clsx(
        'relative block focus:outline-none text-xs sm:text-sm px-2 sm:px-8 py-3 w-full hover:bg-gray-100 active:bg-gray-200',
        spacingClassName,
        disabled ? 'pointer-events-none' : null
      )}
      onClick={handleClick(onClick)}
      {...rest}
    >
      <div className="flex items-center text-gray-800 leading-none hover:text-black">
        {hasIcon ? (
          <div className="w-6">
            <span className={clsx('text-lg', disabled ? 'text-gray-2' : null)}>
              {renderCustomIcon ? renderCustomIcon() : getIcon()}
            </span>
          </div>
        ) : null}
        <div className={clsx('flex-1 text-left', hasIcon ? 'ml-1' : null)}>
          <span>{children}</span>
          {help ? <small className="block text-gray-600 text-xs">{help}</small> : null}
        </div>
      </div>
    </Element>
  )

  return asLink ? <Link {...linkProps}>{content}</Link> : content
}

export default Button
