import Linkify from 'linkify-react'

import { Author } from '@/ts/types/post.types'
import { replaceSubStrings } from '@/utils/strings'

import { sortByKey } from '../../../../utils/sort'
import CompatibleHashtagLink from './CompatibleHashtagLink'
import CompatibleMentionLink from './CompatibleMentionLink'

type Props = {
  /**
   * ODS from file
   */
  selectedODS?: object
  /**
   * Post Author
   */
  author?: Author
}

const CompatibleLinkify = ({ children, hashtags = [], mentions = [], options: _options }: Props): JSX.Element => {
  const hashtagSubstrings =
    hashtags?.map((hashtag) => ({
      ...hashtag,
      renderComponent: (item, value) => <CompatibleHashtagLink key={value} item={item} value={value} />,
    })) || []

  const mentionSubstring =
    mentions?.map((mention) => ({
      ...mention,
      renderComponent: (item, value) => <CompatibleMentionLink key={value} item={item} value={value} />,
    })) || []

  const substrings = sortByKey([...hashtagSubstrings, ...mentionSubstring], 'Start', false)

  const options = {
    ..._options,
    attributes: {
      ..._options?.attributes,
    },
    className: 'text-primary',
  }

  return <Linkify options={options}>{replaceSubStrings(children, substrings)}</Linkify>
}

export default CompatibleLinkify
