import clsx from 'clsx'
import { formatDistanceStrict } from 'date-fns'
import { ReactNode } from 'react'

import { Author, Post } from '@/ts/types/post.types'
import { getDateFnsLocale } from '@/utils/dates'

import Avatar from '../Avatar'
import CustomLink from '../CustomLink'
import ProfileLink from '../ProfileLink'
import PostCreator from './PostCreator'
import PostOptions from './PostOptions'

type Props = {
  children?: ReactNode | string
  className?: string
  Author?: Author
  ownBookmark?: boolean
  ownSubscription?: boolean
  post: Post
  postCreatedAt?: Date | string
  postUUID: string
  index: number
  onDelete: () => void
}

const PostHeader = ({
  Author,
  postUUID,
  postCreatedAt,
  ownBookmark,
  ownSubscription,
  post,
  index,
  onDelete,
}: Props): JSX.Element => {
  /* const selectedODS = Author?.Extra?.ODS
    ? odsIcon.find((o) => o.index === Author.Extra.ODS)
    : null;*/

  return (
    <div className="relative ">
      <div className="flex flex-row items-center justify-between">
        <div className="text-gray-800 text-sm flex items-center flex-row -mx-2">
          <div className="flex items-center justify-center pl-2">
            <ProfileLink type={Author?.Type} uuid={Author?.UUID} className="flex items-center justify-center">
              <Avatar
                user={Author as Author}
                text={Author?.Name}
                levelColor={Author?.LevelColor}
                className={clsx('w-10 h-10 text-white text-sm !rounded-full')}
                image={Author?.Photo?.Thumbnail}
              />
            </ProfileLink>
          </div>
          <div className="flex flex-col flex-1 px-2">
            <div className="leading-tight">
              <PostCreator author={Author} index={index} />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-start relative ">
          <PostOptions
            authorUUID={Author?.UUID as string}
            postUUID={postUUID}
            isFeatured={false}
            ownSubscription={ownSubscription as boolean}
            post={post}
            ownBookmark={ownBookmark as boolean}
            onDeletedBookmark={function (): void {
              throw new Error('Function not implemented.')
            }}
            index={index}
            onDelete={onDelete}
          />
          <CustomLink
            href={`/p/${postUUID}`}
            className="inline ml-2 text-xs text-gray-600 leading-tight text-light hover:underline"
          >
            {postCreatedAt
              ? formatDistanceStrict(new Date(postCreatedAt), new Date(), {
                  locale: getDateFnsLocale(),
                  addSuffix: true,
                })
              : null}
          </CustomLink>
        </div>
      </div>
    </div>
  )
}

export default PostHeader
