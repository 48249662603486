import axios from 'axios'

export const findMetadataByURL = (params: unknown[]): Promise<unknown> => {
  const url = params[0]
  return axios.get(`/api/metadata?url=${url}`)
}

export const generatePDFPreviewFile = async (pdf: File): Promise<File> => {
  const formData = new FormData()
  formData.append('file', pdf)

  console.log('generatePDFPreviewFile pdf', pdf)

  const result = await axios.post('/api/generate-pdf-preview', formData, {
    responseType: 'blob',
  })

  return new File([result.data], 'preview.jpeg')
}

export const getTime = async (): Promise<string> => {
  return await axios.get('/api/time')
}
