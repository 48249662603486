import Article from '@/components/shared/Post/Addons/Article'
import Challenge from '@/components/shared/Post/Addons/Challenge'
import ChallengeCreated from '@/components/shared/Post/Addons/ChallengeCreated'
import Company from '@/components/shared/Post/Addons/Company'
import File from '@/components/shared/Post/Addons/File'
import { GroupExchangeApproved } from '@/components/shared/Post/Addons/GroupExchangeApproved'
import { GroupExchangeRejected } from '@/components/shared/Post/Addons/GroupExchangeRejected'
import { GroupExchangeVote } from '@/components/shared/Post/Addons/GroupExchangeVote'
import Image from '@/components/shared/Post/Addons/Image'
import InitiativeCreated from '@/components/shared/Post/Addons/InitiativeCreated'
import Post from '@/components/shared/Post/Addons/Post'
import Twitter from '@/components/shared/Post/Addons/Twitter'
import UploadedVideo from '@/components/shared/Post/Addons/UploadedVideo'
import Youtube from '@/components/shared/Post/Addons/Youtube'
import { Post as PostType } from '@/ts/types/post.types'

const allowedTypes = [
  'article',
  'company',
  'post',
  'image',
  'file',
  'twitter',
  'youtube',
  'activity',
  'product',
  'initiative',
  'achievement',
  'ODS',
  'video',
  'challenge',
  'challenge-created',
  'initiative-created',
  'reward-reserved',
  'group-reward-rejected',
  'group-reward-success',
]

type ReturnProps = {
  handleClick?: () => void
  addon?: object
  Component?: JSX.Element
  type?: string
}

type Props = {
  post: PostType
}

const useAddon = ({ post }: Props): ReturnProps => {
  const metadata = post?.Metadata
  const isObject = metadata && typeof metadata === 'object'

  const addon = isObject && allowedTypes.indexOf(metadata.Type) > -1 && metadata.Data ? metadata.Data : null

  let Component = null

  if (addon && metadata) {
    switch (metadata.Type) {
      case 'article':
        Component = Article
        break
      case 'company':
        Component = Company
        break
      case 'post':
        Component = Post
        break
      case 'image':
        Component = Image
        break
      case 'file':
        Component = File
        break
      case 'twitter':
        Component = Twitter
        break
      case 'youtube':
        Component = Youtube
        break
      case 'video':
        Component = UploadedVideo
        break
      case 'challenge':
        Component = Challenge
        break
      case 'challenge-created':
        Component = ChallengeCreated
        break
      case 'initiative-created':
        Component = InitiativeCreated
        break
      case 'reward-reserved':
        Component = GroupExchangeVote
        break
      case 'group-reward-success':
        Component = GroupExchangeApproved
        break
      case 'group-reward-rejected':
        Component = GroupExchangeRejected
        break
    }

    return {
      addon,
      Component,
      type: metadata.Type,
    }
  } else {
    return {}
  }
}

export default useAddon
