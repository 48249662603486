import { Dispatch, SetStateAction, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { FilterValue, Ods } from '@/ts/types/general.types'
import { Post } from '@/ts/types/post.types'
import { odsIcon } from '@/utils/ods'

type ReturnProps = {
  handleCreate: (values: Post) => void
  handleSave: (newPost: Post) => void
  handleReset: () => void
  posts: Post[]
  newPosts: Post[]
  objective: FilterValue | null | Ods
  setObjective: Dispatch<SetStateAction<null | FilterValue | Ods>>
}

const useNewPosts = (): ReturnProps => {
  const [posts, setPosts] = useState<Post[]>([])
  const [newPosts, setNewPosts] = useState<Post[]>([])
  const [objective, setObjective] = useState<FilterValue | null | Ods>(odsIcon[0] as Ods)

  const handleCreate = (values: Post) => {
    console.log('Este se envía para en el onCreate en el PostForm: ', values)
    const newValues = {
      ...values,
    }
    const newPosts: Post[] = [{ key: uuidv4(), ...newValues }, ...posts]
    setPosts(newPosts)
  }

  // New posts are inserted in a new array.
  // Then, that array is rendered in the posts feed.
  const handleSave = (newPost: Post) => {
    console.log('ENTER HANDLE SAve: ', newPost)
    const index = posts.findIndex((post) => post.key === newPost.key)
    const filteredPosts = [...posts]
    const updatedNewPosts = [newPost, ...newPosts]
    filteredPosts.splice(index, 1)
    setPosts(filteredPosts)
    setNewPosts(updatedNewPosts)
  }

  const handleReset = () => {
    setNewPosts([])
  }

  return {
    handleCreate,
    handleSave,
    handleReset,
    posts,
    newPosts,
    objective,
    setObjective,
  }
}

export default useNewPosts
