import { Popover } from '@headlessui/react'
import Link from 'next/link'

import useAuthor from '@/hooks/useAuthor'

import AuthorInformationHeader from '../../AuthorInformation/components/AuthorInformationHeader'
import CustomLink from '../../CustomLink'

type Props = {
  /**
   * Tag Values
   */
  value: {
    Value: string
  }
  /**
   * Value of Link
   */
  item: string
}

const CompatibleHashtagLink = ({ item, value }: Props): JSX.Element => {
  return (
    <Link href={`/tags/${value?.Value}`} className="text-primary hover:underline">
      {item}
    </Link>
  )
}

export default CompatibleHashtagLink
