import clsx from 'clsx'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import { Ods } from '@/ts/types/general.types'
import { Author } from '@/ts/types/post.types'

import Avatar from '../../Avatar'
import CustomLink from '../../CustomLink'

type Props = {
  /**
   * Content for Links
   */
  selectedODS?: Ods
  /**
   * Profile UUID
   */
  author?: Author
}

const AuthorInformationHeader = ({ author, selectedODS }: Props): JSX.Element => {
  return (
    <div className="flex items-center justify-between mb-2 p-4 relative">
      <div
        className="absolute top-0 left-0 w-full h-full bg-gray-500 transition duration-300 pattern-background-overlay"
        style={{ background: selectedODS ? selectedODS.color : '#418BC5' }}
      />
      <div className="w-10 h-10  border-2 border-white bg-white relative z-20" style={{ borderRadius: 14 }}>
        <Avatar
          text={author?.FullName}
          className="w-full h-full text-white text-sm"
          image={author?.Photo?.Thumbnail}
          objective={selectedODS}
        />
      </div>
      <CustomLink href={`/profile/${author?.Type?.substring(0, 1)}/${author?.UUID}`}>
        <Button
          size={ButtonSize.Medium}
          appearance={ButtonAppearance.Primary}
          className={clsx('relative z-20', selectedODS ? selectedODS.color : 'text-gray-800')}
        >
          Ir al perfil
        </Button>
      </CustomLink>
    </div>
  )
}

export default AuthorInformationHeader
