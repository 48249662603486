import { useEffect, useState } from 'react'

import Spinner from '@/components/base/Spinner'
import useGoogleTranslate from '@/hooks/useGoogleTranslate'

import CompatibleLinkify from './CompatibleLinkify'

type Props = {
  /**
   * Related Post UUID
   */
  postUUID?: string
  /**
   * Hashtag array for content
   */
  hashtags?: unknown[]
  /**
   * Mentions array for content
   */
  mentions?: unknown[]
  /**
   * To Limit content
   */
  limitContentCharacters?: boolean
  /**
   * Basic string
   */
  content: string
}

const DefaultContent = ({
  postUUID,
  hashtags,
  mentions,
  limitContentCharacters = true,
  content,
}: Props): JSX.Element => {
  const [readMode, setReadMode] = useState(false)
  const { translateText, loadingTranslate } = useGoogleTranslate()

  const [translated, setTranslated] = useState(false)
  const [translatedContent, setTranslatedContent] = useState<string[]>([])
  const [contentRef, setContentRef] = useState<string>()

  const trimmedContent = content

  const translateContent = async (content: string[]) => {
    setTranslated(false)
    const arrayOfTranslations = await translateText(content, 'es')
    setTranslatedContent(arrayOfTranslations)
    setTranslated(true)
  }

  const defaultContentName = 'defaultContent'
  useEffect(() => {
    if (document?.getElementById(`${defaultContentName}-${postUUID}`)) {
      setContentRef(document?.getElementById(`${defaultContentName}-${postUUID}`)?.innerHTML)
    }
  }, [readMode])

  useEffect(() => {
    if (translated && readMode) {
      translateContent([contentRef as string])
    }
  }, [contentRef])

  return (
    <div className="text-black text-md links break-words whitespace-pre-line max-w-xs sm:max-w-full">
      <div id={`${defaultContentName}-${postUUID}`}>
        <CompatibleLinkify
          hashtags={hashtags}
          mentions={mentions}
          options={{
            target: { url: '_blank' },
            attributes: {
              rel: 'noopener noreferrer',
              uniquekey: 'linkify-' + postUUID,
            },
          }}
        >
          {readMode || !limitContentCharacters ? trimmedContent : trimmedContent?.slice(0, 256)}
        </CompatibleLinkify>
      </div>

      {limitContentCharacters ? (
        <div className="inline">
          {trimmedContent?.length >= 256 && !readMode ? '...' : ''}{' '}
          {trimmedContent?.length >= 256 && !readMode ? (
            <button className="cursor-pointer text-primary" onClick={() => setReadMode(true)}>
              Ver más
            </button>
          ) : null}
        </div>
      ) : null}

      <div className="mt-5">
        {!translated ? (
          <button className="cursor-pointer text-primary font-bold" onClick={() => translateContent([contentRef])}>
            Ver traducción
          </button>
        ) : translatedContent?.length > 0 ? (
          <span>
            <hr className="pt-2 pb-2" />
            <div
              dangerouslySetInnerHTML={{
                __html: translatedContent[0]['translatedText'],
              }}
            />
          </span>
        ) : null}

        {loadingTranslate ? (
          <div className="w-full h-10 flex items-center">
            <Spinner className="text-gray flex-grow" size={16} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default DefaultContent
