import Image from 'next/image'
import { useRouter } from 'next/router'
import useSWR from 'swr'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'
import PercentageBar from '@/components/base/PercentageBar'
import { findInitiativeByUUID } from '@/services/initiativesService'
import { ODSIcon } from '@/ts/types/general.types'
import { InitiativeType } from '@/ts/types/initiatives.types'
import { getLocaleText } from '@/utils/i18n'
import { odsIcon } from '@/utils/ods'

const InitiativeCreated = ({ addon }) => {
  const router = useRouter()
  const handleRedirect = () => {
    router.push(`/initiatives/${addon.InitiativeUUID}`)
  }

  const { data: initiativeData } = useSWR<InitiativeType>(addon.InitiativeUUID, findInitiativeByUUID)
  console.log(initiativeData)
  const odsData: ODSIcon = odsIcon[addon.ODS - 1] || odsIcon[12]

  const percentage = (initiativeData?.[0]?.Current * 100) / initiativeData?.[0]?.Goal || 0
  const fixedPercentage = +percentage.toFixed(percentage >= 1 || percentage === 0 ? 0 : 2) || 0

  return (
    <div
      onClick={initiativeData && handleRedirect}
      className={`relative flex flex-col gap-1 items-center h-full rounded-xl mb-2 drop-shadow-md bg-white ${
        initiativeData && 'cursor-pointer'
      }`}
    >
      <div className={`w-full h-60 relative p-4 pb-2`}>
        <div
          className={`absolute flex items-center gap-2 m-4 top-3 right-3 z-10 px-3 py-2 rounded-lg ${odsData.bgColor}`}
          style={{ backgroundColor: odsData.color }}
        >
          <Image alt={odsData.title()} className="" src={odsData.image} width={11} height={11} />
          <CustomText
            element="span"
            size={TextSize.Tiny}
            weight={FontWeight.Bold}
            className="text-white whitespace-pre"
          >
            {odsData.title()}
          </CustomText>
        </div>
        <img alt={getLocaleText(addon.Name)} src={addon.Image} className="object-cover h-full w-full rounded-xl" />
      </div>
      <div className="flex flex-col w-full p-4 pt-0 gap-2">
        <div className="flex gap-2 items-center">
          <CustomText size={TextSize.SubTitle1} weight={FontWeight.SemiBold}>
            {getLocaleText(initiativeData?.Title || addon.Title)}
          </CustomText>
          <CustomIcon name={IconsTypes.heart} className="text-primary" size={20} />
        </div>
        <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold} className="text-[#323F4B] truncate">
          {getLocaleText(initiativeData?.[0]?.Description || addon?.Description)}
        </CustomText>
        {!!initiativeData?.[0] && (
          <div>
            <PercentageBar percentage={fixedPercentage} round={false} />
          </div>
        )}
      </div>
    </div>
  )
}

export default InitiativeCreated
