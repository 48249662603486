import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { FiX } from 'react-icons/fi'

import Button, { ButtonAppearance } from '@/components/base/Button'
import useAddon from '@/hooks/useAddon'
import { Post as PostType } from '@/ts/types/post.types'

type Props = {
  item?: string
  videoFileStatus?: any
  post: PostType
  onClose?: () => void
  className?: string
  onChange?: () => void
  editable?: boolean
  horizontalSpacingClassName?: string
  isInViewpor?: boolean
  isPostModal?: boolean
}

const PostAddons = ({
  post,
  onClose,
  className,
  isInViewport,
  onChange,
  editable = false,
  isPostModal = false,
  horizontalSpacingClassName,
}: Props): JSX.Element => {
  const { addon, Component } = useAddon({ post })

  return (
    <AnimatePresence>
      {addon ? (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: '100%' }}
          exit={{ opacity: 0, height: 0 }}
          className={clsx('block', className)}
        >
          <div className="relative">
            {onClose ? (
              <div className="absolute z-10 -right-2 -top-2">
                <Button className="h-6 w-6" appearance={ButtonAppearance.Primary} onClick={onClose}>
                  <FiX />
                </Button>
              </div>
            ) : null}
            {Component ? (
              <Component
                post={post}
                content={post.Content}
                addon={addon}
                isInViewport={isInViewport}
                onChange={onChange}
                editable={editable}
                horizontalSpacingClassName={horizontalSpacingClassName}
                isPostModal={isPostModal}
              />
            ) : null}
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default PostAddons
