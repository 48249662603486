import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import { mutate } from 'swr'

import { useAuthStore } from '@/dataStores/auth'
import { postURLEndpoint, toggleSavePostByPostUUIDAndAuthorUUID } from '@/services/postsService'

type Props = {
  postUUID: string
  ownBookmark?: boolean
  onDeletedBookmark?: () => void
}

type ReturnProps = {
  handleClick: () => void
  loading?: boolean
}

const useSavePost = ({ postUUID, ownBookmark, onDeletedBookmark }: Props): ReturnProps => {
  const [user] = useAuthStore((state) => [state.user])

  const [loading, setLoading] = useState<boolean>(false)

  const router = useRouter()
  const isSavedFeed = !!router.route.match(/\/profile\/u\/([^ ]+)\/saved/g)

  const handleSavePost = async () => {
    setLoading(true)
    try {
      await toggleSavePostByPostUUIDAndAuthorUUID(postUUID, user?.UUID as string)

      if (isSavedFeed) {
        onDeletedBookmark && onDeletedBookmark()
        await mutate({
          page: postURLEndpoint,
          postUUID: postUUID,
        })
      }

      toast.success(ownBookmark ? `Publicación eliminada con éxito.` : `Publicación guardada con éxito.`)
      await mutate({
        page: postURLEndpoint,
        postUUID: postUUID,
      }) // Post detail
    } catch (error) {
      console.error('TODO: useFeatured  ERROR', error)
    } finally {
      setLoading(false)
    }
  }

  const handleClick = useCallback(async () => {
    await handleSavePost()
  }, [postUUID, ownBookmark])

  return { handleClick, loading }
}

export default useSavePost
