import { AxiosResponse } from 'axios'

import { CreateEvent, FindCompanyAnalyticsByUUIDAndAggregateArgs } from '@/ts/types/analytics.types'

import axios from '../utils/axios'

export const createEvent = ({
  companyUUID,
  name,
  resourceUUID,
  userUUID,
}: CreateEvent): Promise<AxiosResponse<void>> => {
  return axios.post('/analytics/events', {
    CompanyUUID: companyUUID,
    Name: name,
    ResourceUUID: resourceUUID,
    UserUUID: userUUID,
  })
}

export const findCompanyAnalyticsByUUIDAndAggregate = ({
  companyUUID,
  aggregate,
}: FindCompanyAnalyticsByUUIDAndAggregateArgs): Promise<AxiosResponse<void>> => {
  return axios.post('/analytics/events/query', {
    CompanyUUID: companyUUID,
    Aggregate: aggregate,
  })
}
