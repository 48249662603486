import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useState } from 'react'

import { ArticleAddon } from '@/ts/types/post.types'

import CustomLink from '../../CustomLink'

type Props = {
  addon: ArticleAddon
}

const Article = ({ addon }: Props): JSX.Element => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [rendered, setRenredered] = useState(false)
  const showImage = imageLoaded ?? true // if imageLoaded is undefined or null => true
  const image = showImage ? addon.Image : null

  useEffect(() => {
    setRenredered(true)
  }, [])

  return (
    <div className="sm:mx-6 sm:rounded-lg overflow-hidden-z">
      <CustomLink
        type="external"
        href={addon.URL}
        className="block w-full overflow-hidden-z"
        target="_blank"
        rel="noopener noreferrer"
      >
        {image && rendered ? (
          <div className="max-h-md w-full mx-auto bg-gray-300">
            <Image
              src={image}
              onLoad={() => setImageLoaded(true)}
              onError={() => setImageLoaded(false)}
              alt={addon.Title}
              className={clsx('w-full h-full object-cover', imageLoaded ? 'opacity-100' : 'opacity-0')}
            />
          </div>
        ) : null}
        <div
          className={clsx(
            image ? 'block relative sm:absolute bottom-0 left-0 sm:p-2 w-full ' : 'max-w-full overflow-hidden'
          )}
        >
          <div className="w-full relative sm:rounded-lg bg-white shadow-lg p-4 text-gray-800 border border-gray-200">
            {addon.URL ? (
              <span className="block m-0 text-light text-gray-700 leading-none text-xs">
                {new URL(addon.URL).hostname}
              </span>
            ) : null}

            {addon.Title ? (
              <h2 className="text-sm sm:text-base font-semibold tracking-wide leading-none">{addon.Title}</h2>
            ) : null}

            {addon.Description ? <p className="text-xs tracking-wide line-clamp-1">{addon.Description}</p> : null}
          </div>
        </div>
      </CustomLink>
    </div>
  )
}

export default Article
