import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { mutate } from 'swr'

import { useAuthStore } from '@/dataStores/auth'
import { generatePDFPreviewFile } from '@/services/internalService'
import { createPost, findPostByUUID } from '@/services/postsService'
import { Author, Post } from '@/ts/types/post.types'
import { handleError, handleSuccess } from '@/utils/alerts'

import useFileUploader from './useFileUploader'
import useRequest from './useRequest'

type Props = {
  values: Post
  onSuccess: (newPost: Post) => void
  customKey: string
  namespace: string
}

type ReturnProps = {
  author: Author | null
  loading: boolean
  error?: string
}

const usePostUploader = ({ values, onSuccess, customKey, namespace = 'feed' }: Props): ReturnProps => {
  const [user] = useAuthStore((state) => [state.user])

  const { saveFile, loading: loadingFile, error: errorSaveFile } = useFileUploader()
  const { execWithCallback, error: errorCreatePost, loading: loadingPost } = useRequest(createPost)

  const { exec: loadPost, error: errorLoadPost } = useRequest(findPostByUUID)
  const [imageError, setImageError] = useState()
  const [objective, setObjective] = useState(0)

  const [metadataLoading, setMetadataLoading] = useState(false)

  useEffect(() => {
    if (errorCreatePost && errorCreatePost !== undefined) {
      handleError(errorCreatePost, 'posts')
    }
  }, [errorCreatePost])

  useEffect(() => {
    if (errorLoadPost && errorLoadPost !== undefined) {
      handleError(errorLoadPost, 'posts')
    }
  }, [errorLoadPost])

  useEffect(() => {
    console.log('imageError', imageError)
    if (imageError && imageError !== undefined) {
      handleError(imageError, 'posts')
    }
  }, [imageError])

  const error = errorCreatePost || errorSaveFile

  const submitPost = async (values: Post) => {
    if (objective > 0) {
      values.Extra = { ...values.Extra, ODS: objective }
    }
    execWithCallback(values)(async (result: Post) => {
      const newPost = await loadPost({ postUUID: result?.UUID })
      await mutate([0, 'check', user?.UUID, customKey, namespace, '/posts'])

      handleSuccess(`Publicación creada con éxito.`)

      onSuccess && onSuccess({ key: values.key, ...newPost })
    })
  }

  const handleSubmit = async (values) => {
    setMetadataLoading(true)
    const cloneValues = cloneDeep(values)
    await handleMetadata(cloneValues)
      .then(async (metadataValues) => {
        await submitPost(metadataValues)
        setMetadataLoading(false)
      })
      .catch((e) => {
        setImageError(e)
        setMetadataLoading(false)
      })
  }

  const handleMetadata = async (values) => {
    if (values?.Metadata) {
      let temp
      switch (values?.Metadata.Type) {
        case 'image':
          temp = []
          for (const data of values.Metadata.Data) {
            const image = await saveFile(data.Temp)
            temp.push(image)
          }
          values.Metadata.Data = temp
          if (!values.Metadata.Data) {
            return null
          }
          break
        case 'file':
          temp = await generatePDFPreviewFile(values.Metadata.Data.Temp)
          console.log('temp create file ', temp)

          values.Metadata.Data = {
            File: await saveFile(values.Metadata.Data.Temp),
            Preview: temp ? await saveFile(temp) : null,
          }
          console.log('temp create file values.Metadata', values.Metadata)

          if (!values.Metadata.Data.File || !values.Metadata.Data.Preview) {
            return null
          }
          break
      }
    }

    return values
  }

  useEffect(() => {
    if (values && !error && !loadingPost && !metadataLoading) {
      setImageError(undefined)
      handleSubmit(values)
    }
  }, [values])

  return { handleSubmit, loading: loadingFile || loadingPost, error, objective, setObjective }
}

export default usePostUploader
