import Image from 'next/image'
import React from 'react'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import CustomText from '@/components/base/CustomText'
import useImageInput from '@/hooks/useImageInput'

import picSrc from '../../../../public/static/images/form/picture.png'

type Props = {
  metadata: object
  onChange: (value: object) => void
  className: string
}

const PhotoInput = ({ metadata, onChange, className }: Props): JSX.Element | null => {
  const { ref, handleOpen, handleChange } = useImageInput({
    onChange,
    maxSize: 10,
  })

  const value = metadata?.Type === 'image' ? metadata.Data : null

  return (
    <div className={className}>
      <input
        key={value}
        ref={ref}
        type="file"
        className="hidden"
        onChange={(e) => handleChange(e?.target?.files || ([] as File[]))}
      />
      <Button
        icon={() => <Image height={16} width={16} src={picSrc} alt="pic-form" />}
        onClick={handleOpen}
        appearance={ButtonAppearance.White}
        size={ButtonSize.Medium}
        className="w-full"
      >
        <CustomText className="inline">Foto</CustomText>
      </Button>
    </div>
  )
}

export default PhotoInput
