import clsx from 'clsx'
import { SlLocationPin } from 'react-icons/sl'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { Author } from '@/ts/types/post.types'

import CustomLink from '../CustomLink'

type Props = {
  /**
   * ODS from file
   */
  selectedODS?: object
  /**
   * Post Author
   */
  author?: Author
  index: number
}

const PostCreator = ({ author }: Props): JSX.Element => {
  const userLocation = !author?.Address ? `${author?.City}, ${author?.Country}` : author?.Address

  return (
    <div className="flex flex-col items-start">
      <CustomText weight={FontWeight.Bold} className="cursor-pointer">
        <CustomLink href={author?.Type === 'user' ? `/profile/u/${author?.UUID}` : `/profile/c/${author?.UUID}`}>
          {author?.Name}
        </CustomLink>
      </CustomText>
      <div className={clsx('flex flex-row items-center gap-1', 'opacity-100')}>
        <SlLocationPin size={14} />
        <CustomText size={TextSize.Tiny} className={clsx('text-gray-2')}>
          {author?.Type === 'user' ? userLocation : author?.Country}
        </CustomText>
      </div>
    </div>
  )
}

export default PostCreator
