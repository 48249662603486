import React, { ReactNode, useEffect, useRef } from 'react'

type Props = {
  children?: ReactNode
  onClickOutside?: () => void
}

const ClickOutsideHandler = ({ children, onClickOutside, ...props }: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (ref.current && !ref.current.contains(target as Node)) {
        onClickOutside && onClickOutside()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  )
}

export default ClickOutsideHandler
