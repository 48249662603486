import { useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import { mutate } from 'swr'

import { useAuthStore } from '@/dataStores/auth'
import {
  postURLEndpoint,
  subscribeToPostByPostUUIDAndAuthorUUID,
  unsubscribeToPostByPostUUIDAndAuthorUUID,
} from '@/services/postsService'

type Props = {
  postUUID: string
  ownSubscription?: boolean
}

type ReturnProps = {
  handleClick: () => void
  loading?: boolean
}

const useSubscription = ({ postUUID, ownSubscription }: Props): ReturnProps => {
  const [user] = useAuthStore((state) => [state.user])
  const [loading, setLoading] = useState<boolean>(false)

  const handleFeatured = async () => {
    setLoading(true)
    try {
      if (ownSubscription) {
        await unsubscribeToPostByPostUUIDAndAuthorUUID(postUUID, user?.UUID as string)
        toast.success('Suscripción desactivada con éxito.')
      } else {
        await subscribeToPostByPostUUIDAndAuthorUUID(postUUID, user?.UUID as string)
        toast.success('Suscripción activada con éxito.')
      }
      await mutate({
        page: postURLEndpoint,
        postUUID: postUUID,
      }) // Post detail
    } catch (error) {
      console.error('TODO: FEATURE POST ERROR', error)
    } finally {
      setLoading(false)
    }
  }

  const handleClick = useCallback(async () => {
    await handleFeatured()
  }, [postUUID, ownSubscription])

  return { handleClick, loading }
}

export default useSubscription
