/* eslint-disable sonarjs/cognitive-complexity */
import { FaBookmark } from 'react-icons/fa'
import { FiBookmark } from 'react-icons/fi'
import { SlOptions } from 'react-icons/sl'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import FloatingCard from '@/components/base/FloatingCard/FloatingCard'
import Spinner from '@/components/base/Spinner'
import { useAuthStore } from '@/dataStores/auth'
import useDelete from '@/hooks/useDelete'
import useReport from '@/hooks/useReport'
import useSavePost from '@/hooks/useSavePost'
import useSubscription from '@/hooks/useSubscription'
import { Post } from '@/ts/types/post.types'

type Props = {
  /**
   * Post  UUID
   */
  postUUID: string
  /**
   * Post author UUID
   */
  authorUUID: string
  /**
   * Featured post status
   */
  isFeatured: boolean
  /**
   * On Delete Callback
   */
  onDelete?: () => void
  /**
   * Suscribed to Post status
   */
  ownSubscription: boolean
  /**
   * Post data for editing purposes
   */
  post: Post
  /**
   * Post Bookmarkerd status
   */
  ownBookmark: boolean
  /**
   * Post delete bookmark callback
   */
  onDeletedBookmark: () => void
  index: number
}

const PostOptions = ({
  postUUID,
  authorUUID,
  onDelete,
  ownSubscription,
  post,
  ownBookmark,
  onDeletedBookmark,
  index,
}: Props): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])

  const { handleClick: handleDelete, loading: loadingDelete } = useDelete({
    postUUID,
    onDelete,
  })

  const { handleClick: handleReport, loading: loadingReport } = useReport({
    postUUID,
  })

  const { handleClick: handleSavePost, loading: loadingSaveStatus } = useSavePost({
    postUUID,
    ownBookmark,
    onDeletedBookmark,
  })

  const { handleClick: handleSubscription, loading: loadingSubscription } = useSubscription({
    postUUID,
    ownSubscription,
  })

  //TODO: edit
  const isOP =
    (user && authorUUID === user.UUID) ||
    (user && user?.Companies && user?.Companies?.length > 0
      ? user.Companies.map((c) => c.UUID).indexOf(authorUUID) > -1
      : null)

  const optionsLoading = loadingDelete || loadingReport || loadingSaveStatus || loadingSubscription

  return (
    <div className="relative">
      <FloatingCard
        renderTrigger={(toggle) => (
          <Button
            as="div"
            size={ButtonSize.Medium}
            className="flex flex-row items-center justify-center"
            icon={() => {
              return !optionsLoading ? (
                <SlOptions />
              ) : (
                <div>
                  <Spinner size={14} className="text-gray-800" />
                </div>
              )
            }}
            onClick={toggle}
            appearance={ButtonAppearance.White}
          />
        )}
      >
        <FloatingCard.Button onClick={handleReport} icon="Flag" help={`Avísanos si esta publicación es inadecuada.`}>
          <span>Reportar</span>
        </FloatingCard.Button>

        <FloatingCard.Button
          onClick={handleSavePost}
          renderCustomIcon={ownBookmark ? () => <FaBookmark /> : () => <FiBookmark />}
          help={ownBookmark ? `Eliminar de publicaciones guardadas.` : `Agregar a publicaciones guardadas. `}
        >
          {ownBookmark ? <span>Eliminar publicación</span> : <span>Guardar publicación</span>}
        </FloatingCard.Button>

        <FloatingCard.Button
          onClick={handleSubscription}
          icon={ownSubscription ? 'BellOff' : 'Bell'}
          help={
            ownSubscription
              ? `Dejarás de recibir actualizaciones sobre esta publicación.`
              : `Recibirás actualizaciones sobre esta publicación.`
          }
        >
          {ownSubscription ? <span>Cancelar suscripción</span> : <span>Suscribirse</span>}
        </FloatingCard.Button>

        {isOP ? (
          <FloatingCard.Button onClick={handleDelete} icon="Trash" help={`Deberás confirmar la acción.`}>
            <span>Eliminar</span>
          </FloatingCard.Button>
        ) : null}
      </FloatingCard>
    </div>
  )
}

export default PostOptions
