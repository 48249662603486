import * as Sentry from '@sentry/node'

import { createEvent } from '@/services/analyticsService'
import { CreateEvent, RegisterGoogleAnalyticsEventArgs } from '@/ts/types/analytics.types'

import { isProductionENV } from '../config'

export const GA_TRACKING_ID = 'G-0SXECZP2JS'

const allowAnalytics = isProductionENV && typeof window !== 'undefined'

export const registerGoogleAnalyticsPageView = (url: string, userID: string) => {
  if (allowAnalytics) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
      user_id: userID,
    })
  }
}

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @param {string} action
 * @param {string} category
 * @param {string} label
 * @param {number} value
 */
export const registerGoogleAnalyticsEvent = ({ action, category, label, value }: RegisterGoogleAnalyticsEventArgs) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

/**
 * Captures an event and send it to the internal analytics API.
 * @param {string} companyUUID - Target company UUID.
 * @param {string} name - Event name. It could be any.
 * @param {string} [resourceUUID] - May be a post, or product UUID.
 * @param {string} [userUUID] - Useful to capture unique events.
 * @return {Promise<void>}
 */
export const registerInternalAnalyticsEvent = async ({ companyUUID, name, resourceUUID, userUUID }: CreateEvent) => {
  if (allowedInternalAnalyticsEventNames.indexOf(name) === -1) {
    Sentry.captureMessage(`Event with name '${name} does not exists.'`, {
      extra: {
        companyUUID,
        name,
        resourceUUID,
        userUUID,
      },
    })
  }

  try {
    await createEvent({
      companyUUID,
      name,
      resourceUUID,
      userUUID,
    })
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        companyUUID,
        name,
        resourceUUID,
        userUUID,
      },
    })
  }
}

const allowedInternalAnalyticsEventNames = [
  'view-company',
  'click-company',
  'view-post',
  'click-contact',
  'view-product',
  'click-product',
]
