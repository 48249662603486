/* eslint-disable require-jsdoc */
import axios from 'axios'

import { googleAppsAPIKey } from '../config'

const googleTranslateApiURL = 'https://translation.googleapis.com/language/translate/v2'

export const translateLanguage = async (params) => {
  if (params.q[7] === undefined) {
    params.q[7] = ''
  }

  const qParam = params.q
    .map((n: string) => `q=${encodeURIComponent(n.replace(new RegExp('\r?\n', 'g'), '<br />'))}`)
    .join('&')

  const response = await axios
    .post(
      googleTranslateApiURL + '?' + qParam,
      {},
      {
        params: {
          target: params.target,
          key: googleAppsAPIKey,
        },
      }
    )
    .then((response) => {
      return response.data.data
    })

  return response['translations']
}
