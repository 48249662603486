import Image from 'next/image'
import { FiDownload } from 'react-icons/fi'

import { FileAddon } from '@/ts/types/post.types'

import CustomLink from '../../CustomLink'

type Props = {
  /**
   * Content for Card
   */
  addon: FileAddon
}

const File = ({ addon }: Props): JSX.Element => {
  return (
    <div className="sm:mx-6 rounded-lg border-t border-l border-r border-gray-200 flex flex-col items-center justify-center">
      {addon.Temp ? (
        <div className="bg-white p-4 text-gray-800 ">
          <strong>{addon.Temp.name}</strong>
          <span className="text-sm">({Math.round(addon.Temp.size / 1024)}KB)</span>
        </div>
      ) : null}

      {addon.File ? (
        <div className="bg-white text-brand w-full">
          <CustomLink
            href={addon.File?.URL}
            target="_blank"
            rel="noreferrer"
            className="flex flex-col items-center w-full"
            type="external"
          >
            {addon.Preview ? (
              <div className="mb-4 w-full">
                <Image
                  src={addon.Preview?.URL}
                  alt="File Preview"
                  height={200}
                  width={300}
                  className="w-full object-cover align-top object-top"
                  style={{ maxHeight: '50vh' }}
                />
              </div>
            ) : null}

            <div className="w-full border-t border-gray-200 flex justify-center items-center py-2 text-brand">
              <FiDownload className="mr-4" /> Descargar pdf adjunto
            </div>
          </CustomLink>
        </div>
      ) : null}
    </div>
  )
}

export default File
