import clsx from 'clsx'
import { ReactNode } from 'react'

import { useAuthStore } from '@/dataStores/auth'
import { registerInternalAnalyticsEvent } from '@/utils/analytics'

import CustomLink from './CustomLink'

export type CustomLinkTypes = 'internal' | 'external' | undefined

type Props = {
  /**
   * Content for Links
   */
  children?: ReactNode | string
  /**
   * Profile UUID
   */
  uuid: string
  /**
   * Click function
   */
  onClick?: () => void
  /**
   * Classnames
   */
  className?: string
}

const CompanyLink = ({ children, uuid, onClick, className, ...rest }: Props): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])

  const handleClick = () => {
    onClick && onClick()
    registerInternalAnalyticsEvent({
      companyUUID: uuid,
      resourceUUID: uuid,
      name: 'click-company',
      userUUID: user?.UUID as string,
    })
  }

  return (
    <CustomLink className={clsx(className)} href={`/profile/c/${uuid}`} onClick={handleClick} {...rest}>
      {children}
    </CustomLink>
  )
}

export default CompanyLink
