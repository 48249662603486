import Image from 'next/image'
import React from 'react'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import CustomText from '@/components/base/CustomText'
import useFileInput from '@/hooks/useFileInput'
import useVideoFileInput from '@/hooks/useVideoFileInput'

import videoSrc from '../../../../public/static/images/form/video.png'

type Props = {
  metadata: object
  onChange: (value: object) => void
  className: string
  disableInput?: boolean
}

const VideoInput = ({ metadata, onChange, className, disableInput = false }: Props): JSX.Element | null => {
  const { ref, handleOpen, handleChange } = useVideoFileInput({
    maxSize: 8,
    onChange: onChange,
    checkMaxSize: false,
  })

  const value = metadata?.Type === 'video' ? metadata.Data : null

  return (
    <div className={className}>
      {!disableInput ? (
        <input
          key={value}
          ref={ref}
          type="file"
          accept="video/mp4,video/x-m4v,video/*"
          className="hidden"
          onChange={handleChange}
        />
      ) : null}
      <Button
        icon={() => <Image height={16} width={16} src={videoSrc} alt="pdf-src" />}
        onClick={!disableInput ? (e) => handleOpen(e) : null}
        appearance={ButtonAppearance.White}
        size={ButtonSize.Medium}
        className="w-full"
      >
        <CustomText className="inline">Video</CustomText>
      </Button>
    </div>
  )
}

export default VideoInput
