import { useCallback, useState } from 'react'

const useValues = <T,>(
  defaultValues: T
): {
  values: T
  key: number
  handleChange: (key: string) => (value: unknown) => void
  reset: () => void
  updateValues: (keyOrObject: object | string, value?: unknown) => void
} => {
  const [key, setKey] = useState(Date.now())
  const [values, setValues] = useState(defaultValues)

  /* Useful in form inputs: onChange={handleChange('keyName')} */
  const handleChange = useCallback(
    (key: string) => (value: unknown) => {
      setValues({ ...values, [key]: value })
    },
    [values]
  )

  const reset = () => {
    setValues(defaultValues)
    setKey(Date.now())
  }

  /* Update a single value or an object or values */
  const updateValues = useCallback(
    (keyOrObject: object | string, value?: unknown | null) => {
      if (typeof keyOrObject === 'string') {
        handleChange(keyOrObject)(value)
      } else if (typeof keyOrObject === 'object') {
        setValues({ ...values, ...keyOrObject })
      }
    },
    [values]
  )
  return { values, key, handleChange, updateValues, reset }
}

export default useValues
