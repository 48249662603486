import clsx from 'clsx'
import { ReactNode } from 'react'

import { UserTypes } from '@/ts/types/user.types'

import CompanyLink from './CompanyLink'
import CustomLink from './CustomLink'

type Props = {
  /**
   * Content for Links
   */
  children?: ReactNode | string
  /**
   * User type of user
   */
  type?: UserTypes
  /**
   * Profile UUID
   */
  uuid?: string
  /**
   * Classname
   */
  className?: string
}

const ProfileLink = ({ children = '', type, uuid, className, ...rest }: Props): JSX.Element => {
  if (type === 'user') {
    return (
      <CustomLink href={`/profile/u/${uuid}`} className={clsx(className)} {...rest}>
        {children}
      </CustomLink>
    )
  } else {
    return (
      <CompanyLink uuid={uuid as string} className={clsx(className)} {...rest}>
        {children}
      </CompanyLink>
    )
  }
}

export default ProfileLink
