import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import useWindowResize from '@/hooks/useWindowResize'

import Card from '../Card'
import ClickOutsideHandler from '../ClickOutsideHandler'
import DesktopWrapper from '../DesktopWrapper'
import MobileWrapper from '../MobileWrapper'
import Button from './Button'
import Content from './Content'
import { FloatingCardContext } from './Context'
import Divider from './Divider'

type Props = {
  children
  renderTrigger
  align
}

const FloatingCard = ({ children, renderTrigger, align = 'right', ...rest }: Props): JSX.Element => {
  const [open, setOpen] = useState(false)
  const { width } = useWindowResize()

  const handleClick = useCallback(
    (callback) => () => {
      setOpen(false)
      callback && callback()
    },
    []
  )

  const handleTriggerClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <FloatingCardContext.Provider value={{ handleClick }}>
      {width > 640 ? (
        <ClickOutsideHandler onClickOutside={() => setOpen(false)} {...rest}>
          <div {...rest}>
            {renderTrigger(handleTriggerClick)}
            <DesktopWrapper open={open} align={align}>
              <Card className="!p-0 " style={{ minWidth: 300 }}>
                {children}
              </Card>
            </DesktopWrapper>
          </div>
        </ClickOutsideHandler>
      ) : (
        <div {...rest}>
          {renderTrigger(handleTriggerClick)}
          <MobileWrapper open={open} onClose={() => setOpen(false)}>
            <Card className="w-full">{children}</Card>
          </MobileWrapper>
        </div>
      )}
    </FloatingCardContext.Provider>
  )
}

export default FloatingCard

FloatingCard.propTypes = {
  children: PropTypes.any,
  triggerProps: PropTypes.any,
  renderTrigger: PropTypes.any,
  align: PropTypes.oneOf(['left', 'right']),
}

FloatingCard.Divider = Divider
FloatingCard.Content = Content
FloatingCard.Button = Button
