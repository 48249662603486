import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { useAuthStore } from '@/dataStores/auth'
import useImageColor from '@/hooks/useImageColor'
import { findCompanyByUUIDAndBlocks } from '@/services/companiesService'
import { CompanyAddon } from '@/ts/types/post.types'
import { registerInternalAnalyticsEvent } from '@/utils/analytics'

import Avatar from '../../Avatar'
import CompanyLink from '../../CompanyLink'

type Props = {
  addon: CompanyAddon
  isInViewport: boolean
}

const Company = ({ addon, isInViewport }: Props): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])

  const [eventSent, setEventSent] = useState(false)
  const { data: company } = useSWR([addon.UUID, '/companies/[uuid]'], findCompanyByUUIDAndBlocks)

  const color = useImageColor({ image: company?.Logo?.Thumbnail })

  useEffect(() => {
    if (isInViewport && !eventSent && company) {
      setEventSent(true)
      registerInternalAnalyticsEvent({
        companyUUID: company?.UUID,
        name: 'view-company',
        userUUID: user?.UUID,
      })
    }
  }, [isInViewport, company])

  return (
    <div className="sm:mx-6 sm:rounded-lg overflow-hidden-z">
      <CompanyLink
        uuid={company?.UUID}
        className="block bg-gray-100 pattern-background-overlay hover:opacity-75 transition duration-300"
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundColor: color }}
      >
        {!color ? <div className="absolute top-0 left-0 bg-gray-700 bg-opacity-25 w-full h-full z-10" /> : null}
        <div className="flex flex-col items-center py-4 relative z-20">
          <div className="relative w-full mb-2">
            <div className="w-20 sm:w-24 h-20 sm:h-24 rounded-full border-4 border-white mx-auto">
              <Avatar text={addon?.Name} className="w-full h-full text-white text-sm" image={addon?.Logo?.Thumbnail} />
            </div>
          </div>
          <h1 className="font-bold sm:text-lg tracking-wide text-white leading-tight">{addon?.Name}</h1>
          <p className="text-sm sm:text-base text-center text-white leading-tight">
            {addon?.Type === 'company' ? 'Empresa' : null}
            {addon?.Type === 'institution' ? 'Institución' : null}
          </p>
        </div>
      </CompanyLink>
    </div>
  )
}

export default Company
