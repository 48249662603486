import { useState } from 'react'
import { FaChevronDown, FaTimesCircle } from 'react-icons/fa'

import { ODSModal } from '@/components/base/ODSModal'

type Props = {
  onSelect: (number) => void
}

export const ODSSelector = ({ onSelect }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(null)

  const handleChange = (option) => {
    setValue(option.name)
    onSelect(option.index)
  }

  const handleResetValue = (e) => {
    e.stopPropagation()
    setValue(null)
    onSelect(null)
  }

  return (
    <>
      <ODSModal onChange={handleChange} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div
        onClick={() => setIsOpen(true)}
        className="flex hover:border-gray-2 justify-between items-center w-full p-2 border border-gray-3 rounded-md cursor-pointer"
      >
        <span>{!value ? 'Selecciona una ODS' : value}</span>
        <div className="flex items-center gap-3 pr-1 text-gray-3 hover:text-gray-2">
          {value && <FaTimesCircle className="hover:text-red" size={16} onClick={handleResetValue} />}
          <div className="w-[1px] h-6 bg-gray-3" />
          <FaChevronDown className="" size={14} />
        </div>
      </div>
    </>
  )
}
