import { MutableRefObject, useCallback, useRef, useState } from 'react'

import { parseFormatAndCheckImage } from '../utils/files'

type Props = {
  onChange: (value: object) => void
  maxSize?: number
}

type ReturnProps = {
  ref: MutableRefObject<HTMLInputElement | null>
  preview?: string
  handleOpen: () => void
  handleChange: (acceptedFiles: File[] | FileList) => Promise<void>
}

const useImageInput = ({ onChange, maxSize = 10 }: Props): ReturnProps => {
  const [preview, setPreview] = useState<string>()
  const ref = useRef<HTMLInputElement>(null)

  const handleChange = useCallback(
    async (acceptedFiles: File[] | FileList) => {
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0]
        const value = await parseFormatAndCheckImage(file, maxSize)

        if (value) {
          setPreview(value.URL)
          onChange && onChange(value)
        }
      }
    },
    [onChange]
  )

  const handleOpen = useCallback(() => {
    if (ref?.current) {
      ref?.current.click()
    }
  }, [])

  return { ref, preview, handleOpen, handleChange }
}

export default useImageInput
