import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { findMetadataByURL } from '@/services/translateService'

type ReturnProps = {
  handleClick: () => void
}

type Props = {
  text: string
}

const useContentMetadataDetection = ({ text, onChange, enabled, metadataType }: Props): ReturnProps => {
  const [link, setLink] = useState(null)
  const [type, setType] = useState('')
  const [key, setKey] = useState(Date.now())
  const { data } = useSWR(link && type === 'article' ? [link, '/api/metadata?url'] : null, findMetadataByURL)

  const handleNewLink = () => {
    // Checks Tweet
    const twitterExpression = /(^|[^'"])(https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+))/gi
    const twitterMatches = [...link.matchAll(twitterExpression)]
    if (twitterMatches[0]?.[3]) {
      setType('twitter')
      onChange({ Type: 'twitter', Data: { ID: twitterMatches[0][4] } })
      return
    }

    // Checks Youtube
    const youtubeExpression =
      /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/gi
    const youtubeMatches = [...link.matchAll(youtubeExpression)]
    if (youtubeMatches[0]?.[1]) {
      setType('youtube')
      onChange({ Type: 'youtube', Data: { ID: youtubeMatches[0][1] } })
      return
    }

    // When type is 'article', SWR will handle the request and eventually the onChange callback will be called with the SEO data.
    if (metadataType !== 'image' && metadataType !== 'youtube' && metadataType !== 'file') {
      setType('article')
    }
  }

  useEffect(() => {
    setKey(Date.now())
    if (text && enabled) {
      setType(null)
      const expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi
      const matches = text.match(expression)
      if (matches?.length > 0) {
        setLink(matches[0])
      }
    } else {
      setLink('')
    }
  }, [text])

  useEffect(() => {
    if (onChange && link && text) {
      handleNewLink()
    }

    if (onChange && link && text && type === 'article') {
      onChange({ Type: 'article', Data: data })
    }
  }, [data, link, text, key])

  return {
    loading: !data && link && type === 'article',
  }
}

export default useContentMetadataDetection
