import clsx from 'clsx'

type Props = {
  /**
   * youtube ID
   */
  addon: string

  /**
   * Classnames
   */
  className: string
  /**
   * Iframe classname
   */
  containerClassname: string
}

const Youtube = ({
  addon,
  className = 'sm:mx-6 sm:rounded-lg',
  containerClassname = 'youtube-iframe-container',
}: Props): JSX.Element => {
  return (
    <div className={clsx(className, ' overflow-hidden-z')}>
      <div className={clsx(containerClassname)}>
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          title={`youtube-addon-${addon}`}
          src={`https://www.youtube.com/embed/${addon}?autoplay=0&fs=1&iv_load_policy=3&showinfo=0`}
        />
      </div>
    </div>
  )
}

export default Youtube
