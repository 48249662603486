import { Author } from '@/ts/types/post.types'
import { SunetUser } from '@/ts/types/user.types'
import { handleError } from '@/utils/alerts'

import useUploadMuxVideo from './useUploadMuxVideo'

type ReturnProps = {
  userData: SunetUser
  postCount: number
  loading: boolean
}

type Props = {
  author: Author
}

const usePostFormHandlers = ({ values, onChange, addons }: Props): ReturnProps => {
  const handlePictureChange = (value) => {
    if (values.Metadata?.Type === 'image' && addons.indexOf('image') > -1) {
      const actualMetadataValues = values?.Metadata?.Data
      if (actualMetadataValues?.length === 4) {
        handleError('internal: up to 4 images only', 'posts')
      } else {
        onChange({
          Metadata: {
            Type: 'image',
            Data: [...(actualMetadataValues || []), value],
          },
        })
      }
    } else {
      onChange({
        Metadata: { Type: 'image', Data: [value] },
      })
    }
  }

  const handleBannerChange = (value) => {
    onChange({
      Metadata: { Type: 'image', Data: [value] },
    })
  }

  const handleFileChange = (value) => {
    onChange({
      Metadata: {
        Type: 'file',
        Data: value,
      },
    })
  }

  const handleYoutubeChange = (value) => {
    onChange({
      Metadata: {
        Type: 'youtube',
        Data: value,
      },
    })
  }

  const handleDroppedFile = async (file) => {
    if (['image/png', 'image/jpeg'].indexOf(file['type']) > -1) {
      if (addons.indexOf('image') === -1) {
        handleError('internal: dropped-image-not-allowed')
        return
      }
      const value = await parseFormatAndCheckImage(file, 10)
      handlePictureChange(value)
    } else if (file['type'] === 'application/pdf') {
      if (addons.indexOf('file') === -1) {
        handleError('internal: dropped-file-not-allowed')
        return
      }
      const value = await parseFormatAndCheckFile(file, 8)
      handleFileChange(value)
    } else {
      handleError('internal: incompatible-dropped-file')
    }
  }

  const handleAddonChange = async (metadata) => {
    onChange({
      Metadata: metadata,
    })
  }

  const handleVideoFileChange = (values) => {
    onChange({
      Metadata: {
        Type: 'video',
        Data: values,
      },
    })
  }

  const { handleVideoUpload, progress, loading } = useUploadMuxVideo({
    onSuccess: handleVideoFileChange,
  })

  return {
    handlePictureChange,
    handleBannerChange,
    handleFileChange,
    handleYoutubeChange,
    handleDroppedFile,
    handleAddonChange,
    handleVideoFileChange: handleVideoUpload,
    videoFileStatus: {
      progress,
      loading,
    },
  }
}

export default usePostFormHandlers
