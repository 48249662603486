import { Tweet } from 'react-twitter-widgets'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { TwitterAddon } from '@/ts/types/post.types'

type Props = {
  /**
   * Content for Card
   */
  addon: TwitterAddon
}

const Twitter = ({ addon }: Props): JSX.Element => {
  return (
    <div className="min-h-20 mx-3">
      <Tweet
        tweetId={addon.ID}
        options={{ align: 'center' }}
        renderError={() => (
          <div className="rounded-lg border border-gray-200 p-4">
            <CustomText weight={FontWeight.Bold} size={TextSize.Heading2}>
              El Tweet que intentas ver no existe.
            </CustomText>
            <CustomText className="leading-tight" size={TextSize.Caption}>
              Es posible que el contenido haya sido eliminado por el autor o que esté temporalmente no disponible.
            </CustomText>
          </div>
        )}
      />
    </div>
  )
}

export default Twitter
