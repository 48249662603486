import Image from 'next/image'
import React from 'react'
import { BsImageFill } from 'react-icons/bs'

import { SharedFile } from '@/ts/types/general.types'

const types = {
  company: `Empresa`,
  user: `Usuario`,
}

type Props = {
  name: string
  type: string
  image: SharedFile | string
  isCertified: boolean
}

const Suggestion = ({ name, type, image, isCertified }: Props): JSX.Element | null => {
  return (
    <div className="flex items-center px-4 py-4 z-50 relative">
      <div className="pr-4">
        {image?.Thumbnail ? (
          <div className="rounded-full overflow-hidden-z w-12 h-12 bg-gray-200 relative">
            <Image alt={name} src={image?.Thumbnail || image} fill />
          </div>
        ) : (
          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-gray-200">
            <BsImageFill className="text-2xl opacity-75 text-gray-400" />
          </div>
        )}
      </div>
      <div className="pr-4">
        <p className="text-sm text-brand leading-tight">{types[type]}</p>
        <p className="text-gray-700 leading-tight flex items-center space-x-1">
          <span>{name}</span>
          {isCertified ? (
            <Image
              width={14}
              height={14}
              src="/static/images/certified.svg"
              alt="Usuario verificado"
              className="ml-2 inline-block"
            />
          ) : null}
        </p>
      </div>
    </div>
  )
}

export default Suggestion
