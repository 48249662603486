import { CountDef } from '@/ts/types/general.types'
import {
  CreateGroupType,
  GroupMember,
  GroupType,
  InvitationType,
  JoinRequest,
  MembershipType,
  UpdateGroupType,
} from '@/ts/types/group.types'
import { Post } from '@/ts/types/post.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'

export const groupURLEndpoint = `/groups`

export const createGroup = async (values: CreateGroupType) => {
  return await axios.post(`${groupURLEndpoint}`, values).then((res: { data: { Data: GroupType } }) => res.data.Data)
}

export const updateGroup = async (uuid: string, values: GroupType) => {
  return await axios
    .put(`${groupURLEndpoint}/${uuid}`, values)
    .then((res: { data: { Data: GroupType } }) => res.data.Data)
}

export const deleteGroup = async (uuid: string) => {
  return await axios.delete(`${groupURLEndpoint}/${uuid}`)
}

export const inviteFollowerToGroup = async (groupUUID: string, userUUID: string) => {
  return await axios.post(`${groupURLEndpoint}/${groupUUID}/invite`, {
    InvitedUUID: userUUID,
  })
}

export const acceptInvitationToGroup = async (invitationUUID: string, status: string) => {
  return await axios.post(`${groupURLEndpoint}/change-invitation-status`, {
    InvitationUUID: invitationUUID,
    Status: status,
  })
}

export const inviteByEmailToGroup = async (uuid: string, email: string, message: string) => {
  return await axios.post(`${groupURLEndpoint}/${uuid}/invite-by-email`, {
    InvitedEmail: email,
    Message: message,
  })
}

export const findAllAdminGroups = async (page = 0, limit = 5) => {
  return await axios
    .get(`${groupURLEndpoint}/my-groups?Role=admin&include=Role`)
    .then((res: { data: { Data: CountDef<GroupType> } }) => res.data.Data)
}

export const findAllMemberGroups = async (page = 0, limit = 5) => {
  return await axios
    .get(`${groupURLEndpoint}/my-groups?Role=member&include=Role&offset=${page * limit}&limit=${limit}`)
    .then((res: { data: { Data: CountDef<GroupType> } }) => res.data.Data)
}

export const findGroupCount = async () => {
  return await axios.get(`${groupURLEndpoint}/my-groups?offset=0&limit=0`)
}

export const findAllGroupsPage = async ({ page }: { page: number }) => {
  return await axios
    .get(`${groupURLEndpoint}?offset=${page * 6}&limit=6`)
    .then((res: { data: { Data: CountDef<GroupType> } }) => res.data.Data)
}

export const findAllMyGroupsByRole = async ({ page, role, limit }: { page: number; role: string; limit: number }) => {
  return await axios
    .get(`${groupURLEndpoint}/my-groups?Role=${role}&offset=${page * limit}&limit=${limit}`)
    .then((res: { data: { Data: CountDef<GroupType> } }) => res.data.Data)
}

export const findAllRecommendedGroups = async ({ page, limit }: { page: number; limit: number }) => {
  return await axios
    .get(`${groupURLEndpoint}/interest?offset=${page * limit}&limit=${limit}`)
    .then((res: { data: { Data: CountDef<GroupType> } }) => res.data.Data)
}

export const findAllGroupsPageNotMember = async (page) => {
  return await axios
    .get(`${groupURLEndpoint}?offset=${page * 5}&limit=5&Role=null`)
    .then((res: { data: { Data: CountDef<GroupType> } }) => res.data.Data)
}

export const findAllFollowersMembersByGroup = async (page = 0, uuid: string) => {
  return await axios.get(`${groupURLEndpoint}/members/follow?GroupUUID=${uuid}&offset=${page * 5}&limit=5&include=User`)
}

export const findMembershipByGroupUUIDAndAuthorUUID = async ({
  groupUUID,
  authorUUID,
}: {
  groupUUID: string
  authorUUID: string
}): Promise<MembershipType> => {
  console.log(
    '**** URL MEMBERSHIP *****',
    `${groupURLEndpoint}/members?GroupUUID=${groupUUID}&AuthorUUID=${authorUUID}&include=User`
  )
  return await axios
    .get(`${groupURLEndpoint}/members?GroupUUID=${groupUUID}&AuthorUUID=${authorUUID}&include=User`)
    .then((res: { data: { Data: MembershipType } }) => res.data.Data[0])
}

export const findInvitationByGroupUUIDAndAuthorUUID = async ({
  groupUUID,
  authorUUID,
}: {
  groupUUID: string
  authorUUID: string
}): Promise<InvitationType> => {
  console.log(
    '**** URL INVITATIONS *****',
    `${groupURLEndpoint}/invitations?GroupUUID=${groupUUID}&InvitedUUID=${authorUUID}&Status=pending`
  )

  return await axios
    .get(`${groupURLEndpoint}/invitations?GroupUUID=${groupUUID}&InvitedUUID=${authorUUID}&Status=pending`)
    .then((res: { data: { Data: InvitationType } }) => res.data.Data[0])
}

export const findAllNonFollowersMembersByGroup = async (page = 0, uuid: string) => {
  return await axios.get(
    `${groupURLEndpoint}/members/not-follow?GroupUUID=${uuid}&offset=${page * 5}&limit=5&include=User`
  )
}

export const findAllTopicsByGroupAndPage = async (page = 0, uuid: string) => {
  return await axios
    .get(`/posts/group/trending/hashtags?GroupUUID=${uuid}&offset=${page * 10}&limit=10`)
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const findAllPostsByTopicAndGroup = async (page = 0, uuid: string, tagName: string) => {
  return await axios
    .get(`/posts/by-tag/${tagName}?GroupUUID=${uuid}&offset=${page * 10}&limit=10`)
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const findAllInterestedGroups = async (page = 0) => {
  return await axios
    .get(`${groupURLEndpoint}/interest?offset=${page * 5}&limit=5`)
    .then((res: { data: { Data: CountDef<GroupType> } }) => res.data.Data)
}

export const findJoinRequestByGroupAndUserUUID = async ({
  groupUUID,
  userUUID,
}: {
  groupUUID: string
  userUUID: string
}) => {
  return await axios
    .get(`${groupURLEndpoint}/join-request?GroupUUID=${groupUUID}&MemberRequestUUID=${userUUID}&Status=pending`)
    .then((res: { data: { Data: JoinRequest[] } }) => res.data.Data)
}

export const findGroupByUUID = async ({ groupUUID, accessToken }): Promise<GroupType> => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(`${groupURLEndpoint}/${groupUUID}?include=Admin,TotalMembers,Wallet`)
    .then((res: { data: { Data: GroupType } }) => res.data.Data)
}

export const joinGroup = async (uuid: string) => {
  return await axios.post(`${groupURLEndpoint}/${uuid}/join`)
}

export const leaveGroup = async (uuid: string) => {
  return await axios.post(`${groupURLEndpoint}/${uuid}/leave`)
}

export const deleteMember = async (uuid: string) => {
  return await axios.delete(`${groupURLEndpoint}/members/${uuid}`)
}

export const confirmMemberRequest = async (uuid: string, requestUUID: string) => {
  return await axios.post(`${groupURLEndpoint}/${uuid}/handle-join-request`, {
    JoinRequestUUID: requestUUID,
    Status: 'confirmed',
  })
}

export const handleGroupInvitation = async (invitationUUID: string, status: string) => {
  return await axios.post(`${groupURLEndpoint}/change-invitation-status`, {
    InvitationUUID: invitationUUID,
    Status: status,
  })
}

export const deleteMemberRequest = async (uuid: string) => {
  return await axios.delete(`${groupURLEndpoint}/join-request/${uuid}`)
}

export const findAllJoinRequestByGroup = async (page: number, uuid: string): Promise<CountDef<JoinRequest>> => {
  return await axios
    .get(`${groupURLEndpoint}/${uuid}/join-requests?include=User&offset=${page * 5}&limit=5&Status=pending`)
    .then((res: { data: { Data: CountDef<JoinRequest> } }) => res.data.Data)
}

export const findAllMembersByGroup = async (page: number, uuid: string): Promise<CountDef<GroupMember>> => {
  return await axios
    .get(`${groupURLEndpoint}/members?GroupUUID=${uuid}&include=User&offset=${page * 5}&limit=5`)
    .then((res: { data: { Data: CountDef<GroupMember> } }) => res.data.Data)
}

export const countAllJoinRequestByGroup = async (uuid: string) => {
  return await axios.get(`${groupURLEndpoint}/${uuid}/join-requests?offset=0&limit=0&Status=pending`)
}

export const findAllPostByGroup = async (page = 0, groupUUID: string) => {
  return await axios
    .get(
      `/posts/group-feed?order=-Score,-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&GroupUUID=${groupUUID}&ApprovalStatus=approved`
    )
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const findAllPendingPosts = async (page = 0, groupUUID: string) => {
  return await axios.get(
    `/posts/group-feed?order=CreatedAt&offset=${page * 5}&limit=5&GroupUUID=${groupUUID}&ApprovalStatus=pending`
  )
}
export const findAllFilesPostByGroup = async (page = 0, groupUUID: string) => {
  return await axios
    .get(
      `/posts/group-feed?order=-Score,CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&GroupUUID=${groupUUID}&Metadata.Type=file&ApprovalStatus=approved`
    )
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const findAllImagesPostByGroup = async (page = 0, groupUUID: string) => {
  return await axios
    .get(
      `/posts/group-feed?order=-Score,-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&GroupUUID=${groupUUID}&Metadata.Type=image&ApprovalStatus=approved`
    )
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const findAllLinksPostByGroup = async (page = 0, groupUUID: string) => {
  return await axios
    .get(
      `/posts/group-links-feed?GroupUUID=${groupUUID}&order=-Score,-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&ApprovalStatus=approved`
    )
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const findAllVideoPostByGroup = async (page = 0, groupUUID: string) => {
  return await axios
    .get(
      `/posts/group-feed?order=-Score,-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&GroupUUID=${groupUUID}&Metadata.Type=video&ApprovalStatus=approved`
    )
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const findPostsCountByGroup = async (uuid: string) => {
  return await axios.get(`posts/group-feed?GroupUUID=${uuid}&offset=0&limit=0`)
}

export const findAllGroupPostByUser = async (page = 0) => {
  return await axios
    .get(
      `/posts/my-groups-feed?order=-Score,-CreatedAt&include=LikeCount,Group,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&ApprovalStatus=approved`
    )
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const searchGroupsByQuery = async (query: string) => {
  return await axios
    .get(`${groupURLEndpoint}/search?Query=${query}&offset=0&limit=10&include=Admin`)
    .then((res: { data: { Data: CountDef<GroupType> } }) => res.data.Data)
}
export const importMembersWithFileAndValues = async (file, values) => {
  const formData = new FormData()
  formData.append('File', file)
  formData.append('GroupUUID', values.GroupUUID)
  formData.append('CompanyUUID', values.CompanyUUID)
  formData.append('CompanyName', values.CompanyName)
  formData.append('GroupName', values.GroupName)
  return axios.post('/users/excel', formData)
}

export const findAllAdminGroupsWithWallet = async (accessToken) => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return await axiosInstance
    .get(`/groups/my-admin-groups?HasWallet=true&include=Wallet`)
    .then((res: { data: { Data: GroupType[] } }) => res.data.Data)
}

export const createGroupWallet = async (groupUUID) => {
  return await axios.post(`/groups/${groupUUID}/wallet`, {})
}

export const createGroupTransaction = async (values) => {
  return await axios.post(`/groups/transactions`, values)
}

export const getGroupTransactions = async (groupUUID) => {
  const res = await axios.get(`/gamification/user-transactions/groups?GroupUUID=${groupUUID}&order=-CreatedAt`)
  return res.data.Data
}

export const getExchangeVotes = async (authorUUID, groupRewardUUID) => {
  const res = await axios.get(
    `/gamification/rewards/group-votes?AuthorUUID=${authorUUID}&GroupRewardUUID=${groupRewardUUID}`
  )
  return res.data.Data
}

export const voteExchange = async (values) => {
  return await axios.post(`/gamification/rewards/group-votes`, values)
}

export const reverseGroupTransaction = async (groupTransactionUUID) => {
  return await axios.put(`/groups/transactions/${groupTransactionUUID}/reverse`)
}
