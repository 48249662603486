import { searchUsersByQuery } from '@/services/authenticationServices'
import { searchCompaniesByQuery } from '@/services/companiesService'
import { sortByKey } from '@/utils/sort'

import useDebounce from './useDebounce'
import useRequest from './useRequest'

type ReturnProps = {
  handleData: (search: any) => Promise<any>
  loading: boolean
}

const useMentionSuggestions = (): ReturnProps => {
  const { exec: searchCompanies, loading: loadingCompanies } = useRequest(searchCompaniesByQuery)
  const { exec: searchUsers, loading: loadingUsers } = useRequest(searchUsersByQuery)

  const loading = useDebounce<boolean>({
    value: loadingCompanies || loadingUsers,
    delay: 100,
  })

  const getSuggestions = async (search: string) => {
    if (!search) {
      return []
    }

    let companies = await searchCompanies(search)
    let users = await searchUsers(search)
    companies = companies.Items || []
    users = users.Items || []

    if (companies?.length > 0) {
      companies = companies.map((item) => ({
        display: item.Name,
        id: `company:${item.UUID}`,
        type: item.Type,
        image: item.Logo,
        similarity: item.Similarity,
        isCertified: true,
        data: item,
      }))
    }

    if (users?.length > 0) {
      users = users.map((item) => ({
        display: `${item.FirstName} ${item.LastName}`,
        id: `user:${item.UUID}`,
        type: 'user',
        image: item.Photo,
        similarity: item.Similarity,
        isCertified: item.IsCertified,
        data: item,
      }))
    }

    return sortByKey([...companies, ...users], 'similarity', true)
  }

  const handleData = async (search, callback) => {
    callback(await getSuggestions(search))
  }

  return { handleData, loading }
}

export default useMentionSuggestions
