import { MutableRefObject, useCallback, useRef } from 'react'

import { handleError } from '../utils/alerts'

type Props = {
  onChange: (value: object) => void
  maxSize: number
}

type ReturnProps = {
  ref: MutableRefObject<HTMLInputElement | null>
  preview?: string
  handleOpen: () => void
  handleChange: (
    e: UIEvent & {
      target: HTMLInputElement & {
        files: Array<string>
      }
    }
  ) => Promise<void>
}

const useFileInput = ({ onChange, maxSize = 5 }: Props): ReturnProps => {
  const ref = useRef<HTMLInputElement>(null)

  const handleChange = useCallback(
    async (e: UIEvent & { target: HTMLInputElement & { files: Array<string> } }) => {
      if (e.target.files?.length > 0) {
        const file = e.target.files[0]

        if (file['type'] !== 'application/pdf') {
          handleError('internal: file is not pdf', 'files')
          return
        }

        if (file['size'] / 1024 / 1024 > maxSize) {
          handleError('internal: file size greater than 8MB', 'files')
          return
        }

        onChange && onChange({ Temp: file })
      }
    },
    [onChange]
  )

  const handleOpen = useCallback(() => {
    if (ref?.current) {
      ref.current.click()
    }
  }, [])

  return { ref, handleOpen, handleChange }
}

export default useFileInput
