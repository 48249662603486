import useSWR from 'swr'

import { findPostByUUID } from '@/services/postsService'
import { Post } from '@/ts/types/post.types'

import DefaultContent from '../Content/DefaultContent'
import PostAddons from '../PostAddons'
import PostHeader from '../PostHeader'

type Props = {
  /**
   * Content for Card
   */
  addon: Post
}

const Post = ({ addon }: Props): JSX.Element => {
  const { data: post } = useSWR([addon.UUID, '/post/{uuid}'], findPostByUUID, {
    fallbackData: addon,
  })

  const metadata = !post.Image ? post.Metadata : null

  return (
    <div className="sm:rounded-lg overflow-hidden-z border border-gray-200 sm:pb-6 mb-4 lg:mb-6 max-w-xs sm:max-w-full">
      <div className="px-4 md:px-6 py-4 w-full">
        <PostHeader
          post={post}
          Author={post?.Included?.Author}
          postUUID={post?.UUID}
          postCreatedAt={post?.CreatedAt}
          ownSubscription={post?.Included?.OwnSubscription}
          ownBookmark={post?.Included?.OwnBookmark}
        />
        <div className="mt-5">
          <DefaultContent
            content={post?.Content as string}
            postUUID={post.UUID}
            hashtags={post.Hashtags}
            mentions={post.Mentions}
          />

          {metadata ? <PostAddons post={post} className="pt-3" /> : null}
        </div>
      </div>
    </div>
  )
}

export default Post
