// Define the Murmur3Hash function
export function MurmurHash3(str: string): () => number {
  let i = 0
  let hash = 1779033703 ^ str.length
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  for (i, hash; i < str.length; i++) {
    const bitwise_xor_from_character = hash ^ str.charCodeAt(i)
    hash = Math.imul(bitwise_xor_from_character, 3432918353)
    hash = (hash << 13) | (hash >>> 19)
  }
  return () => {
    // Return the hash that you can use as a seed
    hash = Math.imul(hash ^ (hash >>> 16), 2246822507)
    hash = Math.imul(hash ^ (hash >>> 13), 3266489909)
    return (hash ^= hash >>> 16) >>> 0
  }
}

export function SimpleFastCounter32(seed_1: number, seed_2?: number, seed_3?: number, seed_4?: number) {
  return () => {
    seed_1 >>>= 0
    seed_2 >>>= 0
    seed_3 >>>= 0
    seed_4 >>>= 0
    let cast32 = (seed_1 + seed_2) | 0
    seed_1 = seed_2 ^ (seed_2 >>> 9)
    seed_2 = (seed_3 + (seed_3 << 3)) | 0
    seed_3 = (seed_3 << 21) | (seed_3 >>> 11)
    seed_4 = (seed_4 + 1) | 0
    cast32 = (cast32 + seed_4) | 0
    seed_3 = (seed_3 + cast32) | 0
    return (cast32 >>> 0) / 4294967296
  }
}

// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
// https://www.delftstack.com/howto/javascript/javascript-random-seed-to-generate-random/
// https://www.educative.io/answers/how-to-generate-a-random-number-between-a-range-in-javascript
export function generateRandom(str: string, min = 0, max = 100) {
  // find diff
  const difference = max - min

  const seed = MurmurHash3(str)
  let rand = SimpleFastCounter32(seed())()

  // multiply with difference
  rand = Math.floor(rand * difference)

  // add with min value
  rand = rand + min

  return rand
}

export function colorsUsingRandom(str: string, colorPalette: string[]): string {
  const rand = generateRandom(str, 0, colorPalette.length)
  return colorPalette[rand]
}
