import { useState } from 'react'

import { translateLanguage } from '@/services/translateService'

const getNavigatorLanguage = (locale = 'en') => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  } else {
    return navigator.language || locale
  }
}

type ReturnProps = {
  translateText: (q, target: string) => string[]
  loadingTranslate?: boolean
}

const useGoogleTranslate = (): ReturnProps => {
  // const { locale } = useLanguage();
  const locale = 'es'
  const [loading, setLoading] = useState(false)

  const translateText = async (q, target) => {
    setLoading(true)
    const finalTarget = target ? target : getNavigatorLanguage(locale).split('-')[0]

    try {
      const translatedText = await translateLanguage({
        q,
        target: finalTarget,
      })
      setLoading(false)
      return translatedText
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return { translateText, loadingTranslate: loading }
}

export default useGoogleTranslate
