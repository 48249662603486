import ColorThief from 'colorthief'
import { useEffect, useState } from 'react'

import { rgbToHex } from '@/utils/colors'

type Props = {
  image?: string
}

type ReturnProps = {
  color: string | null
}

const useImageColor = ({ image }: Props): ReturnProps => {
  const [color, setColor] = useState(null)

  useEffect(() => {
    if (image) {
      const colorThief = new ColorThief()
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.src = image

      img.addEventListener('load', function () {
        const res = colorThief.getColor(img)
        setColor(rgbToHex(...res))
      })
    }
  }, [image])

  return { color }
}

export default useImageColor
