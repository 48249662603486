import Image from 'next/image'
import { useRouter } from 'next/router'
import useSWR from 'swr'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'
import { challengeURLEndpoint, findChallengeByUUID } from '@/services/challengeService'
import { ChallengeType } from '@/ts/types/challenges.types'
import { ODSIcon } from '@/ts/types/general.types'
import { getLocaleText } from '@/utils/i18n'
import { odsIcon } from '@/utils/ods'

const ChallengeCreated = ({ addon, post }) => {
  const router = useRouter()
  const author = post?.Included?.Author
  const handleRedirect = () => {
    router.push(`/challenges/${addon.ChallengeUUID}?evidenceAuthorUUID=${author.UUID}`)
  }

  const { data: challengeData } = useSWR<ChallengeType>(
    { url: challengeURLEndpoint, challengeUUID: addon.ChallengeUUID },
    findChallengeByUUID
  )
  const odsData: ODSIcon = odsIcon[addon.ODS - 1] || odsIcon[12]

  return (
    <div
      onClick={challengeData && handleRedirect}
      className={`relative flex flex-col gap-1 items-center h-full border rounded-xl mb-2 ${
        challengeData && 'cursor-pointer'
      }`}
    >
      <div className="flex justify-between w-full items-center p-4">
        <div className="flex gap-3 m:gap-4 items-center">
          <CustomIcon name={IconsTypes.community} className="text-primary" size={26} />
          <CustomText size={TextSize.SubTitle1} weight={FontWeight.SemiBold}>
            {getLocaleText(challengeData?.Name || addon.Name)}
          </CustomText>
        </div>
      </div>
      <div className={`w-full h-60 relative`}>
        <div
          className={`absolute flex items-center gap-2 m-4 top-0 right-0 z-10 px-3 py-2 rounded-lg ${odsData.bgColor}`}
          style={{ backgroundColor: odsData.color }}
        >
          <Image alt={odsData.title()} src={odsData.image} width={11} height={11} />
          <CustomText
            element="span"
            size={TextSize.Tiny}
            weight={FontWeight.Bold}
            className="text-white whitespace-pre"
          >
            {odsData.title()}
          </CustomText>
        </div>
        <img alt={getLocaleText(addon.Name)} src={addon.Image} className="object-cover h-full w-full rounded-b-md" />
      </div>
    </div>
  )
}

export default ChallengeCreated
