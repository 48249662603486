import { useState } from 'react'

import { useAlert } from '@/providers/alert/context'
import { deletePostByUUID, findAllPostByFilters } from '@/services/postsService'

import useNewPosts from './useNewPosts'
import usePosts from './usePosts'

type Props = {
  postUUID: string
  onDelete?: () => void
}

type ReturnProps = {
  handleClick: () => void
  loading?: boolean
}

const useDelete = ({ postUUID, onDelete }: Props): ReturnProps => {
  const { showConfirm } = useAlert()

  const { handleReset, newPosts } = useNewPosts()

  const [loading, setLoading] = useState<boolean>(false)

  const namespace = 'feed'
  const customKey = 'default'
  const request = (requestData: unknown[]) =>
    findAllPostByFilters(requestData, {
      area: null,
      ods: null,
      date: null,
    })

  const deletePost = async () => {
    setLoading(true)
    try {
      await deletePostByUUID(postUUID)

      onDelete && (await onDelete())
    } catch (error) {
      console.error('TODO: DELETE POST ERROR')
    } finally {
      setLoading(false)
    }
  }

  const handleClick = () => {
    showConfirm({
      title: `Confirmar acción`,
      content: <span>Estás a un paso de eliminar la publicación. ¿Estás seguro/a?</span>,
      onConfirm: deletePost,
    })
  }

  return { handleClick, loading }
}

export default useDelete
