import { useMemo } from 'react'
import useSWR from 'swr'

import { findUserByUUIDAndBlocks } from '@/services/authenticationServices'
import { findCompanyByUUIDAndBlocks } from '@/services/companiesService'
import { Author } from '@/ts/types/post.types'

type Props = {
  uuid: string
  type?: string
}

type ReturnProps = {
  author: Author | null
  error?: string
}

const useAuthor = ({ uuid, type }: Props): ReturnProps => {
  const { data: user, error: userError } = useSWR(
    !type || type === 'user' ? [uuid, '/users/[uuid]'] : null,
    findUserByUUIDAndBlocks,
    {
      shouldRetryOnError: false,
    }
  )
  const { data: company, error: companyError } = useSWR(
    !type || type === 'company' ? [uuid, '/companies/[uuid]'] : null,
    findCompanyByUUIDAndBlocks,
    { shouldRetryOnError: false }
  )

  const author = useMemo(() => {
    if (company) {
      return {
        UUID: company.UUID,
        Photo: company.Logo,
        Name: company.Name,
        Type: 'company',
        URL: `/profile/c/${company.UUID}`,
        data: company,
      }
    } else if (user) {
      return {
        UUID: user.UUID,
        Photo: user.Photo,
        Name: `${user?.FirstName} ${user?.LastName}`,
        Type: 'user',
        URL: `/profile/u/${user.UUID}`,
        data: user,
      }
    } else if (userError && companyError) {
      return {
        Name: `Usuario desconocido`,
      }
    } else {
      return null
    }
  }, [company, user, userError, companyError])

  return { author: author, error: userError || companyError }
}

export default useAuthor
