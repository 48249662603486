import clsx from 'clsx'
import NextImage from 'next/image'

import { SharedFile } from '@/ts/types/general.types'
import { Post } from '@/ts/types/post.types'

import CustomLink from '../../CustomLink'

type Props = {
  addon: SharedFile[] | { Image: SharedFile }
  post: Post
}

const Image = ({ post, addon }: Props): JSX.Element | undefined => {
  // This is only for legacy support
  const images = Array.isArray(addon) ? addon : [addon?.Image ? addon.Image : addon]

  if (!images || images.length === 0) {
    return undefined
  }

  if (images.length === 1) {
    return (
      <div className="flex overflow-hidden-z border-t border-b border-gray-300 py-1">
        {images.map((image, index) => (
          <SingleImage
            key={index}
            uuid={post.UUID}
            height={image?.Height}
            width={image?.Width}
            content={post.Content}
            lowQuality={image?.LowQuality}
            url={image?.URL}
          />
        ))}
      </div>
    )
  } else if (images.length === 2) {
    return (
      <div className="grid grid-cols-2 overflow-hidden-z gap-1 border-t border-b border-gray-300">
        {images.map((image, index) => (
          <div key={index} className="w-full">
            <SingleImage
              index={index}
              uuid={post.UUID}
              height={image?.Height}
              width={image?.Width}
              content={post.Content}
              lowQuality={image?.LowQuality}
              url={image?.URL}
              forceSquared
            />
          </div>
        ))}
      </div>
    )
  } else if (images.length === 3) {
    return (
      <div className="grid grid-cols-2 overflow-hidden-z gap-1 border-t border-b border-gray-300">
        {images.map((image, index) => (
          <div key={index} className={clsx('w-full', index === 0 ? 'col-span-2 h-40 sm:h-72 overflow-hidden' : '')}>
            <SingleImage
              index={index}
              uuid={post.UUID}
              height={image?.Height}
              width={image?.Width}
              content={post.Content}
              lowQuality={image?.LowQuality}
              url={image?.URL}
              forceSquared
            />
          </div>
        ))}
      </div>
    )
  } else if (images.length > 3) {
    return (
      <div className="grid grid-cols-2 overflow-hidden-z gap-1 border-t border-b border-gray-300">
        {images.slice(0, 4).map((image, index) => (
          <div key={index} className="w-full relative">
            <SingleImage
              index={index}
              uuid={post.UUID}
              height={image?.Height}
              width={image?.Width}
              content={post.Content}
              lowQuality={image?.LowQuality}
              url={image?.URL}
              forceSquared
            />
            {images.length > 4 && index === 3 ? (
              <div className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 flex items-center justify-center pointer-events-none select-none">
                <p className="w-16 h-16 bg-white rounded-full font-extrabold flex items-center justify-center text-gray-700 text-xl">
                  +{images.length - 5}
                </p>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    )
  }
}

export default Image

export const SingleImage = ({ uuid, index = 0, url, lowQuality, width = 310, height = 310, content, forceSquared }) => {
  const overrideWidth = forceSquared ? 620 : width
  const overrideHeight = forceSquared ? 620 : height
  const ratio = overrideWidth / overrideHeight
  const showFullyVisible = ratio > 0.9
  const hasDimensions = overrideWidth && overrideHeight

  return uuid ? (
    <CustomLink href={`/p/${uuid}?photo=${index}`} className="flex-1 w-full h-full">
      <div className={clsx('overflow-hidden-z bg-gray-400', !showFullyVisible ? 'max-h-sm' : '')}>
        {hasDimensions ? (
          <div>
            <NextImage src={url} alt={content} width={overrideWidth} height={overrideHeight} fill={false} />
          </div>
        ) : (
          <NextImage src={url} alt={content} width={overrideWidth} height={overrideHeight} fill />
        )}
      </div>
    </CustomLink>
  ) : (
    <div className="flex-1  justify-center w-full">
      <div className={clsx('overflow-hidden-z bg-gray-400', !showFullyVisible ? 'max-h-sm' : '')}>
        {hasDimensions ? (
          <div>
            <NextImage
              src={url}
              alt={content}
              width={overrideWidth}
              height={overrideHeight}
              fill={false}
              className="m-auto"
            />
          </div>
        ) : (
          <NextImage src={url} alt={content} width={overrideWidth} height={overrideHeight} fill className="m-auto" />
        )}
      </div>
    </div>
  )
}
