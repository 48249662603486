import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { SlLocationPin } from 'react-icons/sl'

import Button, { ButtonSize } from '@/components/base/Button'
import Card from '@/components/base/Card'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { useAuthStore } from '@/dataStores/auth'
import usePostForm from '@/hooks/usePostForm'
import useRequest2 from '@/hooks/useRequest2'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import { RankingData } from '@/ts/types/gamification.types'
import axios from '@/utils/axios'
import { odsIcon } from '@/utils/ods'

import Avatar from '../Avatar'
import PostAddons from '../Post/PostAddons'
import { ODSSelector } from './ODSSelector'
import FileInput from './PostForm/FileInput'
import MentionableInput from './PostForm/MentionableInput'
import MetaDataLoading from './PostForm/MetaDataLoading'
import MultimediaDropzone from './PostForm/MultimediaDropzone'
import PhotoInput from './PostForm/PhotoInput'
import VideoInput from './PostForm/VideoInput'

type Props = {
  authorUUID?: string
  authorName?: string
  resetOnCreate: boolean
  allowImages: boolean
  allowFiles: boolean
  allowVideos: boolean
  allowDetection: boolean
  addons: any
  metadata: any
  onCreate: any
  objective: any
  setObjective: (val: string) => void
  preDefinedObjective: any
  canSelectODS: any
  zIndex: any
  formRef: any
  groupUUID?: string
}

const PostForm = ({
  addons,
  allowImages,
  allowFiles,
  allowDetection,
  metadata: initialMetadata,
  onCreate,
  resetOnCreate = true,
  objective,
  setObjective,
  preDefinedObjective = null,
  canSelectODS = true,
  groupUUID = null,
}: Props): JSX.Element => {
  const router = useRouter()
  const { author } = useSunetAuthors()
  const { filteredAddons, values, key, loadingMetadata, handlers, dropzone, videoFileStatus } = usePostForm({
    allowDetection,
    addons,
    initialMetadata,
    onCreate,
    resetOnCreate,
    setObjective,
    noResetObjective: !canSelectODS,
  })
  const { pathname } = useRouter()
  const [user] = useAuthStore((state) => [state.user])
  const [ranking] = useRequest2({
    requestKey: '/gamification/ranking?AuthorUUID&include=Level',
    params: [author?.UUID],
    request: (userUUID) => axios.get(`/gamification/ranking?AuthorUUID=${userUUID}&Type=token&include=Level`),
  })
  const rankingData: RankingData = ranking?.data?.Data[0]

  const mappedODSOptions = odsIcon.map((ods) => {
    return { value: ods.index, label: ods.title() }
  })
  const textAreaRef = useRef(null)
  const handleFocus = () => {
    textAreaRef.current.focus()
  }
  const navegateToProfile = () => {
    if (author.Type !== 'company') {
      router.push(`/profile/u/${user.UUID}`)
    } else {
      router.push(`/profile/c/${author.UUID}`)
    }
  }

  const isGroups = pathname === '/groups/[groupUUID]'

  const userLocation = !user.Address ? `${user?.City}, ${user?.Country}` : user.Address

  const [contentError, setContentError] = useState(false)

  useEffect(() => {
    if (values.Content && contentError) {
      setContentError(false)
    }
  }, [values.Content])

  return (
    <div {...(dropzone.enabled ? dropzone.getRootProps() : null)} className="focus:outline-none ">
      <input className="hidden" {...(dropzone.enabled ? dropzone.getInputProps() : null)} />

      <Card key={key} className="mb-5 relative !p-0 !py-0">
        {dropzone.enabled ? <MultimediaDropzone isDropzoneActive={dropzone.isDragActive} /> : null}
        <div className="flex flex-row px-4 gap-3 items-center pb-4 pt-8">
          <Avatar
            onClick={navegateToProfile}
            text={values.Author.Name}
            className="w-14 h-14 text-white !rounded-full text-lg cursor-pointer"
            image={values.Author.Photo?.Thumbnail}
            levelColor={rankingData?.Included?.Level?.Color}
            borderWidth="lg"
          />
          <div className="flex flex-col">
            <CustomText
              onClick={navegateToProfile}
              className="text-gray cursor-pointer"
              size={TextSize.SubTitle1}
              weight={FontWeight.SemiBold}
            >
              {values.Author.Name}
            </CustomText>
            <div className="flex gap-2 items-center">
              <SlLocationPin size={14} />
              <CustomText size={TextSize.SubTitle2} className={'text-gray-2'}>
                {author.Type !== 'company' ? userLocation : author.Country}
              </CustomText>
            </div>
          </div>
        </div>
        <section className="px-5 pt-3">
          <div>
            <div className="flex flex-col -mx-2">
              {contentError && (
                <CustomText className="text-black" size={TextSize.Caption}>
                  Debes añadir un mensaje para publicar<span className="text-red">*</span>
                </CustomText>
              )}
              <div
                className={clsx(
                  'flex flex-col flex-1 p-3 border rounded-md min-h-[120px] cursor-text',
                  contentError ? 'border-red' : 'border-gray-3'
                )}
                onClick={handleFocus}
              >
                <MentionableInput
                  ref={textAreaRef}
                  value={values.Content}
                  onChange={(value, plainValue, mentions) =>
                    handlers.updateValues({
                      Content: value,
                      PlainContent: plainValue,
                      Mentions: mentions,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div>
            {loadingMetadata || (videoFileStatus.loading && videoFileStatus.progress !== 100) ? (
              <div className="p-3">
                <MetaDataLoading loading progress={videoFileStatus.progress} />
              </div>
            ) : null}

            {values.Metadata ? (
              <div className="py-3 mt-3">
                <PostAddons
                  post={values}
                  onClose={!initialMetadata ? () => handlers.updateValues('Metadata', null) : null}
                  onChange={handlers.handleAddonChange}
                  horizontalSpacingClassName="px-6"
                  editable
                />
              </div>
            ) : null}
          </div>
          {canSelectODS ? (
            <div className="w-full h-full my-4 mb-8 ">
              <div className="flex flex-row items-center gap-2 mb-2">
                <Image alt="ODS" src="/static/images/ods.png" width={44} height={44} />
                <CustomText weight={FontWeight.SemiBold} className="text-gray-1" size={TextSize.SubTitle2}>
                  Asocia tu publicación a un ODS
                </CustomText>
              </div>
              <ODSSelector onSelect={(data) => setObjective(data)} />
            </div>
          ) : null}
        </section>

        <div className={`bg-gray-4 p-4 rounded-b-lg pb-8 ${isGroups ? 'mt-4' : ''}`}>
          <div className="flex flex-col gap-4 items-center justify-between h-full mb-2 w-full">
            <div className="flex flex-row gap-2 w-full items-center justify-between">
              <PhotoInput metadata={values.Metadata} onChange={handlers.handlePictureChange} className="w-full" />

              <VideoInput
                metadata={values.Metadata}
                onChange={handlers.handleVideoFileChange}
                className="w-full"
                disableInput={videoFileStatus.loading}
              />

              <FileInput metadata={values.Metadata} onChange={handlers.handleChange('Metadata')} className="w-full" />
            </div>
            <div className=" flex flex-row justify-between w-full">
              <div className="flex items-center w-full">
                <Button
                  size={ButtonSize.Big}
                  disabled={videoFileStatus.loading}
                  className="disabled:bg-opacity-50 w-full"
                  onClick={() => {
                    if (!values.Content) {
                      setContentError(true)
                      return
                    }
                    handlers.handleSubmit(objective, groupUUID)
                  }}
                >
                  <span className="text-sm leading-none">Publicar</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default PostForm
