import React, { forwardRef, useRef } from 'react'
import { Mention, MentionsInput } from 'react-mentions'

import useMentionSuggestions from '@/hooks/useMentionSuggestions'

import Suggestion from './Suggestion'
type Props = {
  isDropzoneActive?: boolean
  onlyFiles?: boolean
  value?: any
  onChange: any
}
export default forwardRef(function MentionableInput({ value, onChange, ...rest }: Props, ref: any) {
  const { handleData, loading } = useMentionSuggestions()

  return (
    <MentionsInput
      inputRef={ref}
      value={value}
      onChange={(e, value, plainValue, mentions) => {
        onChange(value, plainValue, mentions)
      }}
      className="content-input"
      placeholder={`Escribe tu publicación...`}
      allowSpaceInQuery
      allowSuggestionsAboveCursor
      {...rest}
    >
      <Mention
        appendSpaceOnAdd
        trigger="@"
        data={handleData}
        isLoading={loading}
        renderSuggestion={(entry) => (
          <Suggestion name={entry.display} image={entry.image} type={entry.type} isCertified={entry.isCertified} />
        )}
      />
    </MentionsInput>
  )
})
