import { useState } from 'react'
import toast from 'react-hot-toast'

import { voteExchange } from '@/services/groupServices'

export const useExchangeVote = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false)

  const handleVote = async (values) => {
    setLoading(true)
    try {
      const res = await voteExchange(values)
      onSuccess && onSuccess()
      toast.success('Votación exitosa')
      return res
    } catch (error) {
      console.error(error)
      toast.error('Error al votar')
    } finally {
      setLoading(false)
    }
  }

  return { loading, handleVote }
}
