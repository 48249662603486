import Modal from './CustomModal'

type Props = {
  open: boolean
  onClose?: any
  children?: any
}

const MobileWrapper = ({ open, onClose, children }: Props): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} zIndex={9999}>
      <div className="w-full h-full flex items-center justify-center p-3">{children}</div>
    </Modal>
  )
}

export default MobileWrapper
