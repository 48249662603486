import Image from 'next/image'
import { useRouter } from 'next/router'
import useSWR from 'swr'

import Button, { ButtonAppearance } from '@/components/base/Button'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import { challengeURLEndpoint, findChallengeByUUID } from '@/services/challengeService'
import { ChallengeType } from '@/ts/types/challenges.types'
import { ODSIcon } from '@/ts/types/general.types'
import { getLocaleText } from '@/utils/i18n'
import { odsIcon } from '@/utils/ods'

import Avatar from '../../Avatar'

const Challenge = ({ addon, post }) => {
  const { author: currentAuthor } = useSunetAuthors()
  const router = useRouter()
  const author = post?.Included?.Author
  const handleRedirect = () => {
    router.push(`/challenges/${addon.ChallengeUUID}?evidenceAuthorUUID=${currentAuthor.UUID}`)
  }

  const { data: challengeData } = useSWR<ChallengeType>(
    { url: challengeURLEndpoint, challengeUUID: addon.ChallengeUUID },
    findChallengeByUUID
  )
  const odsData: ODSIcon = odsIcon[addon.ODS - 1] || odsIcon[12]

  return (
    <div className="relative flex flex-col gap-1 items-center h-full border rounded-xl mb-2">
      <div className="flex justify-between w-full items-center p-4">
        <div className="flex gap-3 m:gap-4 items-center">
          <CustomIcon name={IconsTypes.flag} className="text-primary" size={30} />
          <div className="">
            <div className="flex gap-2">
              <Avatar
                image={author.Photo ? author.Photo.Thumbnail : null}
                text={addon?.UserName}
                className="w-6 h-6 text-xs"
                borderWidth="sm"
              />
              <CustomText size={TextSize.SubTitle2}>{addon.UserName} se unió a:</CustomText>
            </div>
            <CustomText className="!font-medium sm:text-lg text-sm">
              {getLocaleText(challengeData?.Name || addon.Name)}
            </CustomText>
          </div>
        </div>
        {challengeData && (
          <Button
            onClick={handleRedirect}
            appearance={ButtonAppearance.Primary}
            className="!px-2 sm:!px-4 py-1 sm:text-base xs:text-sm text-xs"
          >
            Ver desafío
          </Button>
        )}
      </div>
      <div
        className={`w-full h-60 ${challengeData && 'cursor-pointer'} relative`}
        onClick={challengeData && handleRedirect}
      >
        <div
          className={`absolute flex items-center gap-2 m-4 top-0 right-0 z-10 px-3 py-2 rounded-lg ${odsData.bgColor}`}
          style={{ backgroundColor: odsData.color }}
        >
          <Image alt={odsData.title()} src={odsData.image} width={11} height={11} />
          <CustomText
            element="span"
            size={TextSize.Tiny}
            weight={FontWeight.Bold}
            className="text-white whitespace-pre"
          >
            {odsData.title()}
          </CustomText>
        </div>
        <img alt={getLocaleText(addon.Name)} src={addon.Image} className="object-cover h-full w-full rounded-b-md" />
      </div>
    </div>
  )
}

export default Challenge
