import clsx from 'clsx'
import Image from 'next/image'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { useExchangeVote } from '@/hooks/groups/useExchangeVote'
import useRequest2 from '@/hooks/useRequest2'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import TokenFront from '@/public/static/images/token-front.png'
import { getExchangeVotes } from '@/services/groupServices'

export const GroupExchangeVote = ({ post, addon }) => {
  const { author } = useSunetAuthors()

  const [data, loading, error, revalidate] = useRequest2({
    requestKey: `/gamification/vote/${post.UUID}`,
    params: [author.UUID, addon.GroupRewardUUID],
    request: (a, g) => getExchangeVotes(a, g),
  })

  const onSuccess = () => {
    revalidate()
  }

  const { handleVote, loading: loadingVote } = useExchangeVote({ onSuccess })

  const exchangeName = addon?.RewardName || ''
  const amount = addon?.TokensAmount ?? 0

  const handleExchangeVote = (v) => {
    handleVote({
      GroupRewardUUID: addon.GroupRewardUUID,
      IsPositive: v,
      AuthorUUID: author.UUID,
    })
  }

  const isResponded = data?.length === 1

  const isPositive = isResponded ? data[0].IsPositive : null
  return (
    <div className="bg-white border border-gray-200 rounded-2xl p-2 my-4 flex items-center gap-4">
      <div className="w-[90px] h-[90px] sm:w-[100px] sm:h-[100px]">
        <Image
          className="rounded-xl h-full object-cover w-full"
          src={addon?.Image}
          width={100}
          height={100}
          alt="Exchange"
        />
      </div>
      <div className="flex flex-col gap-1">
        <div>
          <CustomText size={TextSize.Caption}>Canjear:</CustomText>
          <CustomText size={TextSize.Caption} weight={FontWeight.SemiBold}>
            {exchangeName}
          </CustomText>
        </div>
        <div className="flex items-center gap-2">
          <Image width={18} height={18} src={TokenFront} alt="SNT" />
          <CustomText weight={FontWeight.SemiBold}>
            {amount} <span className="font-normal text-sm">SNT</span>
          </CustomText>
        </div>
        {!isResponded && (
          <div className="flex items-center gap-2 flex-wrap">
            <button
              disabled={loadingVote || loading}
              onClick={() => handleExchangeVote(true)}
              className="bg-primary border rounded-lg border-primary px-2 py-0.5 text-white flex items-center gap-2"
            >
              <FaCheckCircle className="text-white" />
              <CustomText size={TextSize.Caption} weight={FontWeight.Medium}>
                A favor
              </CustomText>
            </button>
            <button
              disabled={loadingVote || loading}
              onClick={() => handleExchangeVote(false)}
              className="bg-white border rounded-lg border-primary px-2 py-0.5 text-primary flex items-center gap-2"
            >
              <FaTimesCircle className="text-primary" />
              <CustomText size={TextSize.Caption} weight={FontWeight.Medium}>
                En contra
              </CustomText>
            </button>
          </div>
        )}
        {isResponded && (
          <CustomText weight={FontWeight.SemiBold} className={clsx(isPositive ? 'text-primary' : 'text-red')}>
            {isPositive ? 'A favor' : 'En contra'}
          </CustomText>
        )}
      </div>
    </div>
  )
}
