import { mutate } from 'swr'

import { handleError } from '@/utils/alerts'
import { mutateTokens } from '@/utils/mutation'

import { extractHashtags } from '../utils/hashtags'
import useSunetAuthors from './useSunetAuthors'

const usePostSubmit = ({ values, onCreate, onReset, setObjective, noResetObjective }) => {
  const { author } = useSunetAuthors()
  const handleSubmit = async (objective, groupUUID) => {
    console.log(values, 'post values')
    console.log('Objective: ', objective)
    console.log('groupUUID: ', groupUUID)

    const Mentions = values.Mentions?.map((mention) => {
      const startsWithSpace = mention.display.substring(0, 1) === ' '
      const display = mention.display.trim()

      return {
        AuthorUUID: mention.id.split(':')[1],
        Type: mention.id.split(':')[0],
        Start: mention.plainTextIndex + (startsWithSpace ? 1 : 0), // It does not work as expected, but it fixes the bug anyways.
        End: mention.plainTextIndex + display.length + (startsWithSpace ? 1 : 0),
      }
    })
    console.log('Pass Mentions: ', Mentions)
    const Hashtags = [...(values.Hashtags ? values.Hashtags : []), ...extractHashtags(values.PlainContent)]
    console.log('Pass Hashtags: ', Hashtags)

    const Extra = {
      Country: values.Author.Country,
      ODS: 0,
    }

    if (objective > 0) {
      Extra.ODS = objective
    }

    console.log('Pass Extra: ', Extra)

    onCreate &&
      onCreate({
        ...values,
        AuthorUUID: values.Author?.UUID,
        Mentions,
        Hashtags,
        GroupUUID: groupUUID,
        Extra: Extra,
        Content: values.PlainContent,
        Type: 'default',
      })
    onReset && onReset()

    if (!noResetObjective) {
      setObjective(null)
    }

    await mutateTokens(author.UUID)
    await mutate([author.UUID, '/gamification/ranking?AuthorUUID&include=Level'])
  }

  return { handleSubmit }
}

export default usePostSubmit
