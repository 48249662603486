import { Dialog, Transition } from '@headlessui/react'
import Image from 'next/image'
import { Fragment } from 'react'
import { FaTimes } from 'react-icons/fa'

import { odsList } from '@/utils/odsList'

import CustomText, { FontWeight, TextSize } from './CustomText'

type Props = {
  setIsOpen: (boolean) => void
  isOpen: boolean
  onChange: (number) => void
}

export const ODSModal = ({ setIsOpen, onChange, isOpen }: Props) => {
  function closeModal() {
    setIsOpen(false)
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative" style={{ zIndex: 9999 }} onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md max-h-96  overflow-y-scroll transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div
                    onClick={closeModal}
                    className="absolute bg-gray-4 p-2 rounded-full top-0 right-0 m-4 cursor-pointer"
                  >
                    <FaTimes />
                  </div>
                  <Dialog.Title as="h3" className="font-medium leading-6 text-gray-900 flex gap-2 items-center">
                    <CustomText size={TextSize.SubTitle1} weight={FontWeight.Bold}>
                      Selecciona una ODS
                    </CustomText>
                  </Dialog.Title>
                  <div className="flex flex-wrap p-2 sm:p-4 gap-2 sm:gap-4 justify-center">
                    {odsList.map((item) => (
                      <Image
                        priority
                        key={item.index}
                        onClick={() => {
                          onChange(item)
                          closeModal()
                        }}
                        width={100}
                        height={100}
                        src={item.print}
                        alt={item.name}
                        className="rounded-xl cursor-pointer"
                      />
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
