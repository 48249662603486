import { intersection } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

import { useAuthStore } from '@/dataStores/auth'
import { Author, CreatingPost } from '@/ts/types/post.types'

import useContentMetadataDetection from './useContentMetadataDetection'
import usePostFormHandlers from './usePostFormHandlers'
import usePostSubmit from './usePostSubmit'
import useSunetAuthors from './useSunetAuthors'
import useValues from './useValues'

const availableDropzoneAddons = ['image', 'file']
const addonsByPostType: { [key: string]: string[] } = {
  default: ['image', 'youtube', 'article', 'twitter', 'file'],
}

type Props = {
  uuid: string
  type?: string
}

type ReturnProps = {
  author: Author | null
  error?: string
}

const usePostForm = ({
  allowDetection,
  addons: _addons,
  initialMetadata,
  onCreate,
  resetOnCreate,
  setObjective,
  noResetObjective = false,
}: Props): ReturnProps => {
  const { author, authors } = useSunetAuthors()
  const { authorUUID } = useAuthStore((state) => ({ authorUUID: state.authorUUID }))

  const { values, key, handleChange, updateValues, reset } = useValues<CreatingPost>({
    Content: '',
    Author: author,
    Metadata: initialMetadata,
    Type: 'default',
  })

  const addons = intersection(_addons, addonsByPostType[values.Type]) || []

  const { handleSubmit } = usePostSubmit({
    values,
    onCreate,
    onReset: resetOnCreate ? reset : null,
    setObjective,
    noResetObjective,
  })

  const { loading: loadingMetadata } = useContentMetadataDetection({
    text: values.Content,
    onChange: handleChange('Metadata'),
    enabled: allowDetection && values.Type === 'default', // Detection is only supported in default posts.
    metadataType: values?.Metadata?.Type,
    addons,
  })

  const {
    handlePictureChange,
    handleFileChange,
    handleDroppedFile,
    handleAddonChange,
    handleYoutubeChange,
    handleVideoFileChange,
    videoFileStatus,
  } = usePostFormHandlers({ values, onChange: updateValues, addons })

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles?.length > 0 ? acceptedFiles[0] : null
      if (file) {
        handleDroppedFile(file)
      }
    },
    [values]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  })

  useEffect(() => {
    updateValues({
      Content: '',
      PlainContent: '',
      Metadata: initialMetadata,
      Mentions: [],
      Hashtags: [],
      Extra: undefined,
    })
  }, [])

  useEffect(() => {
    updateValues({
      Author: author,
    })
  }, [authorUUID])

  const dropZoneEnabled = addons.filter((a) => availableDropzoneAddons.indexOf(a) > -1).length > 0

  return {
    filteredAddons: addons,
    authors,
    values,
    key,
    loadingMetadata,
    videoFileStatus,
    handlers: {
      handleSubmit,
      handlePictureChange,
      handleFileChange,
      handleYoutubeChange,
      handleVideoFileChange,
      handleAddonChange,
      updateValues,
      handleChange,
    },
    dropzone: {
      enabled: dropZoneEnabled,
      getRootProps,
      getInputProps,
      isDragActive,
    },
  }
}

export default usePostForm
