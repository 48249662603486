import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode } from 'react'

type Props = {
  /**
   * Content for Card
   */
  children?: ReactNode | string
  /**
   * ClassNames
   */
  className?: string
  /**
   * Bg Color for Circle
   */
  color?: string
  /**
   * Image URL
   */
  image?: string

  open?: boolean

  align?: string
}

const DesktopWrapper = ({ open, children, align }: Props): JSX.Element => {
  return open ? (
    <AnimatePresence>
      <motion.div
        transition={{ duration: 0.2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={clsx(
          'absolute inline-block w-auto h-full bottom-0 z-30 max-w-none whitespace-no-wrap transform translate-y-full pt-1',
          align === 'left' ? 'left-0' : '',
          align === 'right' ? 'right-0' : ''
        )}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  ) : null
}

export default DesktopWrapper
