import { Popover } from '@headlessui/react'

import useAuthor from '@/hooks/useAuthor'

import AuthorInformationHeader from '../../AuthorInformation/components/AuthorInformationHeader'
import CustomLink from '../../CustomLink'

type Props = {
  /**
   * Values to use on useAuthor Hook
   */
  value: {
    AuthorUUID: string
    Type: string
  }
  /**
   * Value of Link
   */
  item?: string
}

const CompatibleMentionLink = ({ item, value }: Props): JSX.Element => {
  const { author } = useAuthor({ uuid: value.AuthorUUID, type: value.Type })

  if (!author) {
    return <span className="text-primary">{item}</span>
  } else {
    return (
      <Popover className="relative">
        <Popover.Button>
          <CustomLink href={author.URL} className="text-primary hover:underline">
            <span>{item}</span>
          </CustomLink>
        </Popover.Button>

        <Popover.Panel className="absolute z-10">
          <AuthorInformationHeader author={author} />
        </Popover.Panel>
      </Popover>
    )
  }
}

export default CompatibleMentionLink
