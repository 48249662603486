import Image from 'next/image'
import React from 'react'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import CustomText from '@/components/base/CustomText'
import useFileInput from '@/hooks/useFileInput'

import pdfSrc from '../../../../public/static/images/form/fileUpload.svg'

type Props = {
  metadata: object
  onChange: (value: object) => void
  className: string
  disableInput?: boolean
}

const FileInput = ({ metadata, onChange, className, disableInput = false }: Props): JSX.Element | null => {
  const { ref, handleOpen, handleChange } = useFileInput({
    maxSize: 8,
    onChange: (value) =>
      onChange({
        Type: 'file',
        Data: value,
      }),
  })

  const value = metadata?.Type === 'image' ? metadata.Data : null

  return (
    <div className={className}>
      {!disableInput ? (
        <input key={value} ref={ref} type="file" accept="application/pdf" className="hidden" onChange={handleChange} />
      ) : null}
      <Button
        icon={() => <Image height={16} width={16} src={pdfSrc} alt="pdf-src" />}
        onClick={!disableInput ? (e) => handleOpen(e) : null}
        appearance={ButtonAppearance.White}
        size={ButtonSize.Medium}
        className="w-full"
      >
        <CustomText className="inline">PDF</CustomText>
      </Button>
    </div>
  )
}

export default FileInput
